import * as React from "react";
import { Spin } from 'antd';

function LoadingAnimation(props) {
  const ComponentHeight = 500;

  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        verticalAlign: "center",
        height: ComponentHeight
      }}
    >
      <Spin tip="Loading..."/>
      {/* <Spinner
        size={SpinnerSize.large}
        label="Loading..."
        ariaLive="assertive"
      /> */}
    </div>
  );
}
export default LoadingAnimation;
