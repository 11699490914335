// import 'react-app-polyfill/ie9'; // For IE 9-11 support
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { freeSet } from "@coreui/icons";
import { icons } from './assets/icons'

import Amplify from "aws-amplify";
import config from "./aws-exports";
Amplify.configure(config);

React.icons = { ...freeSet, ...icons };

//icons
initializeIcons(/* optional base url */);

// initialize the store
// store.initializeStore();

ReactDOM.render(
    <App />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
