export const sygnet = [
  "auto 160",
  `
  <title>pipetrend logo</title>
  <g>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 101">
    <g fill-rule="evenodd" clip-rule="evenodd">
      <path d="M27.8 90.4c-3.1 0-5.6-2.5-5.6-5.6V45.7c0-3.1 2.5-5.6 5.6-5.6s5.6 2.5 5.6 5.6v39.1c0 3.1-2.5 5.6-5.6 5.6" fill="#5280e3"/>
      <path d="M58.5 61.8c-3.1 0-5.6-2.5-5.6-5.6V17c0-3.1 2.5-5.6 5.6-5.6s5.6 2.5 5.6 5.6v39.1c0 3.1-2.5 5.7-5.6 5.7" fill="#68b1f0"/>
      <path d="M42.8 61.8c-3.1 0-5.6-2.5-5.6-5.6V45.7c0-3.1 2.5-5.6 5.6-5.6 3.1 0 5.6 2.5 5.6 5.6v10.4c0 3.1-2.5 5.7-5.6 5.7" fill="#5f9eeb"/>
      <path d="M73.5 43.3c-3.1 0-5.6-2.5-5.6-5.6v-3.4c0-3.1 2.5-5.6 5.6-5.6 3.1 0 5.6 2.5 5.6 5.6v3.4c0 3.1-2.5 5.6-5.6 5.6" fill="#71c4f5"/>
    </g>
  </svg>
  </g>
`,
];
