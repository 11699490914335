import { differenceInDays } from "date-fns";
import {
  DEALSTAGE_CLOSED_WON,
  DEALSTAGE_CLOSED_CHURNED,
} from "../utils/constants";
import {
  formatNumber,
  formatDateLong,
  currencyFormat,
  getNumberFromCurrency,
} from "../utils/helper";
import { processDealOwnerStatistics } from "./analyticsDealOwners";
import pipelineStore from "../store/StorePipeline";
import store from "../store/StoreMain";

function _getHistoryFromPipelineValue(value) {
  var tempList = [];
  try {
    var i;
    for (i = 0; i < pipelineStore.pipelineSnapshots.length; i++) {
      const tempItem = pipelineStore.pipelineSnapshots[i];

      const tempValue = tempItem[value];
      if (tempValue) {
        const tempNumber = isNaN(tempValue)
          ? getNumberFromCurrency(tempValue)
          : tempValue;
        tempList.push(tempNumber);
      }
    }
  } catch (err) {
    console.log("getHistoryFromPipelineValue", err);
  }
  return tempList.reverse();
}

function _getHistoryLabelsFromPipeline() {
  let tempLabels = [];
  try {
    var i;
    for (i = 0; i < pipelineStore.pipelineSnapshots.length; i++) {
      const tempItem = pipelineStore.pipelineSnapshots[i];
      const tempLabel = formatDateLong(tempItem.snapshot_date);
      tempLabels.push(tempLabel);
    }
  } catch (err) {
    console.log("getHistoryFromPipelineValue", err);
  }
  return tempLabels.reverse();
}

export async function calculatePipelineStatistics(
  pipelineId,
  pipelineObject,
  callback
) {
  try {
    // get the pipeline data
    let value_mrr_weighted_90days = 0;
    let value_mrr_weighted = 0;
    let value_mrr_total = 0;
    let value_mrr_average = 0;
    let value_mrr_churned = 0;
    let value_mrr_won = 0;
    let value_mrr_per_dealowner = 0;
    let value_amount_total = 0;
    let value_amount_weighted = 0;
    let value_amount_average = 0;
    let value_amount_churned = 0;
    let value_amount_won = 0;
    let value_amount_per_dealowner = 0;
    let activedeals_count = 0;
    let record_count = 0;
    let score = 0;
    let smells_count = 0;
    let smell_count_lastactivity = 0;
    let smell_mrr_weighted_lastactivity = 0;
    // let smell_count_oldentries_withoutprogress = 0;
    // let smell_mrr_weighted_oldentries_withoutprogress = 0;
    let list_dealowners = new Set();
    let list_countries = new Set();
    let list_dealranges = new Set();
    let list_dealstages = new Set();
    let list_industries = new Set();
    let list_probabilities = new Set();

    var processList = pipelineObject.pipelineItems; // this.actualPipeline.pipelineItems;
    const listLength = processList.length;

    let i = 0;
    for (i = 0; i < listLength; i++) {
      let tempDeal = processList[i];
      record_count++;

      if (tempDeal.isactive === true) {
        activedeals_count++;

        if (!isNaN(tempDeal.value_amount)) {
          value_amount_total = value_amount_total + tempDeal.value_amount;
        }
        if (!isNaN(tempDeal.value_amount_percentage)) {
          value_amount_weighted =
            value_amount_weighted + tempDeal.value_amount_percentage;
        }
        value_mrr_weighted = value_mrr_weighted + tempDeal.value_mrr_percentage;
        value_mrr_total = value_mrr_total + tempDeal.value_mrr;
        if (differenceInDays(tempDeal.closedate, new Date()) < 91)
          value_mrr_weighted_90days =
            value_mrr_weighted_90days + tempDeal.value_mrr_percentage;
        if (
          tempDeal.lastactivitydate &&
          differenceInDays(tempDeal.lastactivitydate, new Date()) < 31
        )
          smell_count_lastactivity++;

        list_dealowners.add(tempDeal.dealowner);
        list_dealstages.add(tempDeal.dealstage);
        list_countries.add(tempDeal.country);
        list_industries.add(tempDeal.customerindustry);
        list_probabilities.add(tempDeal.probability);
      } else {
        // CLOSED WON OR CHURNED
        if (
          !isNaN(tempDeal.value_amount) &&
          tempDeal.dealstage === DEALSTAGE_CLOSED_WON
        ) {
          value_amount_won = value_amount_won + tempDeal.value_amount;
        }
        if (
          !isNaN(tempDeal.value_amount) &&
          tempDeal.dealstage === DEALSTAGE_CLOSED_CHURNED
        ) {
          value_amount_churned = value_amount_churned + tempDeal.value_amount;
        }
        if (
          !isNaN(tempDeal.value_mrr) &&
          tempDeal.dealstage === DEALSTAGE_CLOSED_WON
        ) {
          value_mrr_won = value_mrr_won + tempDeal.value_mrr;
        }
        if (
          !isNaN(tempDeal.value_mrr) &&
          (tempDeal.dealstage === DEALSTAGE_CLOSED_CHURNED ||
            tempDeal.status === "Churned")
        ) {
          value_mrr_churned = value_mrr_churned + tempDeal.value_mrr;
        }
      }
    }

    const dealOwnerCount = list_dealowners.size;
    value_mrr_average =
      activedeals_count > 0
        ? Math.round(value_mrr_total / activedeals_count)
        : 0;
    value_mrr_per_dealowner =
      dealOwnerCount > 0 ? Math.round(value_mrr_total / dealOwnerCount) : 0;
    value_amount_average =
      activedeals_count > 0
        ? Math.round(value_amount_total / activedeals_count)
        : 0;
    value_amount_per_dealowner =
      dealOwnerCount > 0 ? Math.round(value_amount_total / dealOwnerCount) : 0;

    // get the chart labels
    const labelList = _getHistoryLabelsFromPipeline();

    const tempList = [];
    tempList.push({
      key: "value_mrr_weighted_90days",
      description: "MRR % next 90 days",
      icon: "",
      collection: "top,special",
      pushlink: "/dashboard",
      backgroundcolor: "success",
      color: "#3f8600",
      type: "currency",
      value: currencyFormat(value_mrr_weighted_90days),
      value_history: _getHistoryFromPipelineValue("value_mrr_weighted_90days"),
      value_history_label: labelList,
      value_percentage:
        value_mrr_total > 0 ? value_mrr_weighted_90days / value_mrr_total : 0,
    });

    tempList.push({
      key: "value_mrr_weighted",
      description: "MRR % Pipeline",
      icon: "",
      collection: "top,header",
      pushlink: "/dashboard",
      backgroundcolor: "info",
      color: "#4FA581",
      type: "currency",
      value: currencyFormat(value_mrr_weighted),
      value_history: _getHistoryFromPipelineValue("value_mrr_weighted"),
      value_history_label: labelList,
      value_percentage:
        value_mrr_total > 0 ? value_mrr_weighted / value_mrr_total : 0,
    });
    tempList.push({
      key: "value_mrr_total",
      description: "MRR Pipeline",
      icon: "",
      collection: "top,header",
      backgroundcolor: "info",
      color: "#4FA581",
      type: "currency",
      value: currencyFormat(value_mrr_total),
      value_history: _getHistoryFromPipelineValue("value_mrr_total"),
      value_history_label: labelList,
      value_percentage: 1,
    });
    tempList.push({
      key: "value_mrr_average",
      description: "MRR Average",
      icon: "",
      collection: "metric",
      backgroundcolor: "info",
      color: store.colorNeutral,
      type: "currency",
      value: currencyFormat(value_mrr_average),
      value_history: _getHistoryFromPipelineValue("value_mrr_average"),
      value_history_label: labelList,
      value_percentage: 0,
    });
    tempList.push({
      key: "value_mrr_won",
      description: "MRR Won",
      icon: "",
      collection: "info",
      backgroundcolor: "info",
      color: store.colorNeutral,
      type: "currency",
      value: currencyFormat(value_mrr_won),
      value_history: _getHistoryFromPipelineValue("value_mrr_won"),
      value_history_label: labelList,
      value_percentage: 0,
    });
    tempList.push({
      key: "value_mrr_per_dealowner",
      description: "MRR per Deal Owner",
      icon: "",
      collection: "info",
      backgroundcolor: "info",
      color: store.colorNeutral,
      type: "number",
      value: currencyFormat(value_mrr_per_dealowner),
      value_history: _getHistoryFromPipelineValue("value_mrr_per_dealowner"),
      value_history_label: labelList,
      value_percentage: 0,
    });
    tempList.push({
      key: "value_amount_weighted",
      description: "Amount % Pipeline",
      icon: "",
      collection: "metric",
      backgroundcolor: "primary",
      color: store.colorNeutral,
      type: "currency",
      value: currencyFormat(value_amount_weighted),
      value_history: _getHistoryFromPipelineValue("value_amount_weighted"),
      value_history_label: labelList,
      value_percentage:
        value_amount_total > 0 ? value_amount_weighted / value_amount_total : 0,
    });
    tempList.push({
      key: "value_amount_total",
      description: "Amount Pipeline",
      icon: "",
      collection: "metric,header",
      backgroundcolor: "primary",
      color: store.colorNeutral,
      type: "currency",
      value: currencyFormat(value_amount_total),
      value_history: _getHistoryFromPipelineValue("value_amount_total"),
      value_history_label: labelList,
      value_percentage: 1,
    });
    tempList.push({
      key: "value_amount_average",
      description: "Amount Average",
      icon: "",
      collection: "metric",
      backgroundcolor: "primary",
      color: store.colorNeutral,
      type: "currency",
      value: currencyFormat(value_amount_average),
      value_history: _getHistoryFromPipelineValue("value_amount_average"),
      value_history_label: labelList,
      value_percentage: 0,
    });
    tempList.push({
      key: "value_amount_won",
      description: "Amount Won",
      icon: "",
      collection: "metric",
      backgroundcolor: "primary",
      color: store.colorNeutral,
      type: "currency",
      value: currencyFormat(value_amount_won),
      value_history: _getHistoryFromPipelineValue("value_amount_won"),
      value_history_label: labelList,
      value_percentage: 0,
    });
    tempList.push({
      key: "value_amount_churned",
      description: "Amount Churned",
      icon: "",
      collection: "metric",
      backgroundcolor: "primary",
      color: store.colorNegative,
      type: "currency",
      value: currencyFormat(value_amount_churned),
      value_history: _getHistoryFromPipelineValue("value_amount_churned"),
      value_history_label: labelList,
      value_percentage: 0,
    });
    tempList.push({
      key: "value_per_dealowner",
      description: "Amount per Deal Owner",
      icon: "",
      collection: "metric",
      backgroundcolor: "info",
      color: store.colorNeutral,
      type: "currency",
      value: currencyFormat(value_amount_per_dealowner),
      value_history: _getHistoryFromPipelineValue("value_per_dealowner"),
      value_history_label: labelList,
      value_percentage: 0,
    });
    tempList.push({
      key: "record_count",
      description: "Deals All",
      icon: "",
      collection: "",
      backgroundcolor: "secondary",
      color: store.colorNeutral,
      value: formatNumber(record_count),
      value_history: _getHistoryFromPipelineValue("record_count"),
      value_history_label: labelList,
      value_percentage: 0,
    });
    tempList.push({
      key: "activedeals_count",
      description: "Deals",
      icon: "",
      collection: "top,header",
      pushlink: "/deals",
      backgroundcolor: "info",
      color: store.colorNeutral,
      value: formatNumber(activedeals_count),
      value_history: _getHistoryFromPipelineValue("activedeals_count"),
      value_history_label: labelList,
      value_percentage: 0,
    });
    tempList.push({
      key: "dealowner_count",
      description: "Deal Owners",
      icon: "",
      collection: "",
      pushlink: "/dealowners",
      backgroundcolor: "info",
      color: store.colorNeutral,
      value: formatNumber(list_dealowners.size),
      value_history: _getHistoryFromPipelineValue("dealowner_count"),
      value_history_label: labelList,
      value_percentage: 0,
    });
    tempList.push({
      key: "value_mrr_churned",
      description: "MRR Churned",
      icon: "",
      collection: "top",
      backgroundcolor: "warning",
      color: store.colorNegative,
      type: "currency",
      value: currencyFormat(value_mrr_churned),
      value_history: _getHistoryFromPipelineValue("value_mrr_churned"),
      value_history_label: labelList,
      value_percentage: 0,
    });
    if (smell_count_lastactivity > 0)
      tempList.push({
        key: "smell_count_lastactivity",
        statistictype: "smell",
        description: "Smells",
        icon: "",
        collection: "top,header",
        pushlink: "/smells",
        backgroundcolor: "danger",
        color: store.colorNegative,
        value: formatNumber(smell_count_lastactivity),
        value_mrr: formatNumber(smell_mrr_weighted_lastactivity),
        value_history: _getHistoryFromPipelineValue("smell_count_lastactivity"),
        value_history_label: labelList,
        value_percentage:
          activedeals_count > 0
            ? smell_count_lastactivity / activedeals_count
            : 0,
      });

    // calculate the score
    score = 3.4;

    tempList.push({
      key: "score",
      description: "Score",
      icon: "",
      collection: "header",
      pushlink: "/scoring",
      color: store.colorNeutral,
      backgroundcolor: "secondary",
      value: formatNumber(score),
      value_history: _getHistoryFromPipelineValue("score"),
      value_history_label: labelList,
      value_percentage: 0,
    });

    if (pipelineObject.pipelineInfo === "actual") {
      try {
        // get the statistics for deal owners
        const listDealOwners = await processDealOwnerStatistics(
          list_dealowners,
          processList
        );
        pipelineStore.setDealOwnerList(listDealOwners);
        pipelineStore.globalListDealOwners = [...listDealOwners];
      } catch (err) {
        console.log("error deal owners", err);
      }
    }
    // store the short lists
    pipelineObject.listCountries = [...list_countries];
    pipelineObject.listDealOwners = [...list_dealowners];
    pipelineObject.listDealRanges = [...list_dealranges];
    pipelineObject.listDealStages = [...list_dealstages];
    pipelineObject.listProbabilities = [...list_probabilities];
    pipelineObject.pipelineStatistics = [...tempList];
    // get back to the caller
    if (callback) {
      callback(pipelineObject);
    }
  } catch (err) {
    console.log("error", err);
    if (callback) {
      callback(undefined);
    }
  }
}
