import { sygnet } from './sygnet'
import { logoDark } from './logo-dark'
import { logoLight } from './logo-light'

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
  logoDark,
  logoLight
}, {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon
})
