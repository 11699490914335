import { searchDealOwnerObjects } from "./analyticsDealOwners";
import { searchDealObjects } from "./analyticsDeals";
import {
  DEALSTAGE_CLOSED_WON,
  NOT_AVAILAVLE
} from "../utils/constants.js";
import pipelineStore from "../store/StorePipeline";
//import store from "../store/StoreMain";

export function searchObjects(searchkey) {
  let results = [];
  let tempDeals = [];
  let tempDealOwners = [];
  tempDeals = searchDealObjects(searchkey);
  tempDealOwners = searchDealOwnerObjects(searchkey);
  // combine the results
  results = [...tempDeals, ...tempDealOwners];
  return results;
}

export function getWonDealsByGroupField(items, groupField, valueField) {
  const tempList = [];
  let sourceList = pipelineStore.getPipelineItemsFromList(items);
  const tempValueField = (valueField) ? valueField : "value_mrr";
  const tempGroupField = (groupField) ? groupField : "dealowner";

  var i;
  for (i = 0; i < sourceList.length; i++) {
    const tempItem = sourceList[i];
    const tempGroupValue = tempItem[tempGroupField];
    const tempValue = tempItem[tempValueField];
    if (
      tempItem.value_mrr > 200 &&
      tempItem.dealstage === DEALSTAGE_CLOSED_WON
    ) {
      tempList.push({
        type: tempItem.type,
        value: tempValue,
        name: tempItem.name,
        argument: tempGroupValue,
        id: tempItem.dealid
      });
    }
  }
  return tempList;
}

export function getWonDealsByCountry(items, valueField) {
  let tempList = [];
  let sourceList = pipelineStore.getPipelineItemsFromList(items);
  let countriesList = [...pipelineStore.getActualCountries];
  const tempValueField = (valueField) ? valueField : "value_mrr";

  var i;
  for (i = 0; i < sourceList.length; i++) {
    const tempItem = sourceList[i];
    if (tempItem.dealstage === DEALSTAGE_CLOSED_WON) {
      const index = countriesList.findIndex(
        (item) => item.name === tempItem.country
      );
      if (index > -1) {
        countriesList[index].count++;
        countriesList[index].value =
          countriesList[index].value + tempItem[tempValueField];
      }
    }
  }
  // go through the results
  for (i = 0; i < countriesList.length; i++) {
    const resultItem = countriesList[i];
    tempList.push({
      type: "",
      value: resultItem.value,
      name: (resultItem.name) ? resultItem.name : NOT_AVAILAVLE,
      argument: resultItem.count,
      id: resultItem.dealid
    });
  }

  return tempList;
}

export function getPipelineDealsByCountry(items, valueField) {
  let tempList = [];
  let sourceList = pipelineStore.getPipelineItemsFromList(items);
  let countriesList = [...pipelineStore.getActualCountries];
  const tempValueField = (valueField) ? valueField : "value_mrr";

  var i;
  for (i = 0; i < sourceList.length; i++) {
    const tempItem = sourceList[i];
    if (tempItem.isactive === true) {
      const index = countriesList.findIndex(
        (item) => item.name === tempItem.country
      );
      if (index > -1) {
        countriesList[index].count++;
        countriesList[index].value =
          countriesList[index].value + tempItem[tempValueField];
      }
    }
  }
  // go through the results
  for (i = 0; i < countriesList.length; i++) {
    const resultItem = countriesList[i];
    tempList.push({
      type: "",
      value: resultItem.value,
      name: (resultItem.name) ? resultItem.name : NOT_AVAILAVLE,
      argument: resultItem.count,
      id: resultItem.dealid
    });
  }

  return tempList;
}
