import {
  format,
  isSameDay,
  isSameWeek,
  isSameMonth,
  isToday,
  //  isValidDate,
  startOfMonth,
} from "date-fns";
import { de } from "date-fns/locale";

// TEXTS

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, "g"), replace);
}

export function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

export function getValueAsString(value, n) {
  let tempValue = String(value);

  if (isValidDate(value)) {
    tempValue = formatDateTimeNatural(value);
  }

  return tempValue;
}

// NUMBERS

export function formatNumber(value, decimals, metric) {
  return isNaN(value)
    ? parseInt(value).toLocaleString("de-DE", {
        useGrouping: true,
        maximumFractionDigits: decimals,
      })
    : value.toLocaleString("de", { maximumFractionDigits: decimals });
  //return value.toLocaleString({ USER_LOCALE }, { maximumFractionDigits: 0 })
}

export function percentageFormat(value, decimals) {
  return isNaN(value)
    ? parseInt(value*100).toLocaleString("de-DE", {
        useGrouping: true,
        maximumFractionDigits: decimals,
      })+"%"
    : (value * 100).toLocaleString("de", { maximumFractionDigits: decimals }) + "%";
  //return value.toLocaleString({ USER_LOCALE }, { maximumFractionDigits: 0 })
}

export const currencyFormat = (value) =>
  new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);

export function getNumberFromCurrency(value) {
  return Number(value.replace(/[^0-9,-]+/g, ""));
}

// date & time

export function getMonthValue(value) {
  let tempDate = new Date(value);
  return startOfMonth(tempDate);
}

export function formatDateTimeNatural(value) {
  if (value > 0) {
    return format(value, "dd/MM/yyyy");
    // return format(newDate, "yyyy-MM-dd", { locale: de });
  } else {
    let tempDate = new Date(value);
    if (isValidDate(tempDate)) {
      return format(tempDate, "dd/MM/yyyy", { locale: de });
    } else return "";
  }
}

export function formatDateShort(value) {
  if (value > 0) {
    return format(value, "dd/MM/yy");
    // return format(newDate, "yyyy-MM-dd", { locale: de });
  } else {
    let tempDate = new Date(value);
    if (isValidDate(tempDate)) {
      return format(tempDate, "dd/MM/yy", { locale: de });
    } else return "";
  }
}

export function formatDateLong(value) {
  if (value > 0) {
    return format(value, "d. MMMM yyyy");
    // return format(newDate, "yyyy-MM-dd", { locale: de });
  } else {
    let tempDate = new Date(value);
    if (isValidDate(tempDate)) {
      return format(tempDate, "d. MMMM yyyy", { locale: de });
    } else return "";
  }
}

export function formatDateTimeMonth(value) {
  if (value > 0) {
    return format(value, "yyyy/MM");
    //    return format(newDate, "yyyy-MM-dd", { locale: de });
  } else {
    return "";
  }
}
export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function isTheSameDay(date1, date2) {
  return isSameDay(date1, date2);
}

export function isTheSameWeek(date1, date2) {
  return isSameWeek(date1, date2);
}

export function isTheSameMonth(date1, date2) {
  return isSameMonth(date1, date2);
}

export function isDateToday(dateValue) {
  return isToday(dateValue);
}

// EXCEL FUNCTIONALITY
export function getTimeStampAsString(excelTimestamp) {
  if (excelTimestamp > 0) {
    const newDate = new Date(getJsDateFromExcel(excelTimestamp));
    return format(newDate, "yyyy-MM-dd", { locale: de });
  } else {
    return "";
  }
}
export function getJsDateFromExcel(excelDate) {
  if (excelDate) {
    return new Date((excelDate - (25567 + 2)) * 86400 * 1000);
  } else {
    return undefined;
  }
}

export function getDateFromExcel(excelDate) {
  if (excelDate) {
    return new Date(excelDate);
  } else {
    return undefined;
  }
}

export function getDateFromGermanExcel(excelDate) {
  if (isValidDate(excelDate)) {
    return excelDate;
  } else {
    return getJsDateFromExcel(excelDate);
    // var dateParts = excelDate.split(".");
    // // month is 0-based, that's why we need dataParts[1] - 1
    // return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  }
}

export function getCurrencyAsString(value) {
  let tempValue = value;
  if (isNaN(tempValue)) { tempValue = Number(value); }

  try {
    const newValue = tempValue.toLocaleString("de-DE");
    return newValue !== "0" ? newValue : "";
  } catch (err) {
    console.log(err);
  }
}

//SNIPPETS
// const element = document.getElementById("chart");

// return value.toLocaleString("de-DE", {
//   style: "currency",
//   currency: "EUR"
// });

// chart info

// export function getChartValuesFromHistory(datalist) {
//   let newarray = [];
//   if (datalist) {
//     for (var i = 0, len = datalist.items.length; i < len; ++i)
//       newarray.push(datalist.items[i].value);
//   }
//   return newarray;
// }

// export function getChartLabelsFromHistory(datalist) {
//   let newarray = [];
//   if (datalist) {
//     for (var i = 0, len = datalist.items.length; i < len; ++i)
//       newarray.push(moment(datalist.items[i].createdAt).format("ll"));
//   }
//   return newarray;
// }

// function parseDateExcel = excelTimestamp => {
//   const secondsInDay = 24 * 60 * 60;
//   const excelEpoch = new Date(1899, 11, 31);
//   const excelEpochAsUnixTimestamp = excelEpoch.getTime();
//   const missingLeapYearDay = secondsInDay * 1000;
//   const delta = excelEpochAsUnixTimestamp - missingLeapYearDay;
//   const excelTimestampAsUnixTimestamp = excelTimestamp * secondsInDay * 1000;
//   const parsed = excelTimestampAsUnixTimestamp + delta;
//   return isNaN(parsed) ? null : parsed;
// };
