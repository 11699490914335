import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import React, { Component } from "react";
import { Auth, API, graphqlOperation, Hub } from "aws-amplify";
import { getUser as GetUser } from "./graphql/queries";
import { observer } from "mobx-react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { I18n } from "@aws-amplify/core";
import LoadingAnimation from "./components/LoadingAnimation";
import { WelcomeView } from "./views/Welcome/WelcomeView";
import { strings } from "./strings";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "./App.scss";
import store from "./store/StoreMain";
import mixpanel from "mixpanel-browser";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <LoadingAnimation />
  </div>
);

// Containers
const TheLayout = React.lazy(() =>
  import("./containers/DefaultLayout/TheLayout")
);

I18n.putVocabularies(strings);

class App extends Component {
  onAuthEvent(payload) {
    if (payload === "signIn") {
      console.log("user logged in");
    } else if (payload === "signOut") {
      console.log("user logged out");
    }
  }

  // get the pipelines
  componentDidMount() {
    // initialize event tracking
    mixpanel.init(
      "6e67544eedaadeb74a9591e4076ce23a",
      { api_host: "https://api-eu.mixpanel.com" },
      ""
    );

    Hub.listen("auth", (data) => {
      const { payload } = data;
      this.onAuthEvent(payload);
      console.log(
        "A new auth event has happened: ",
        " auth.event: " + data.payload.event
        // data.payload.data.username
      );
    });

    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        const userId = user?.attributes?.sub;
        const userEmail = user?.attributes?.email;
        console.log("logged in User", userId);

        mixpanel.identify(userId);

        // get user detail record_count

        API.graphql(graphqlOperation(GetUser, { id: userId }))
          .then((userDetail) => {
            console.log("Authenticated user", userDetail);

            const userObject = {
              id: userId,
              email: userEmail,
              name: userDetail.data.getUser
                ? userDetail.data.getUser.name
                : "USER",
              avatar: userDetail.data.getUser
                ? userDetail.data.getUser.avatar
                : "",
              license: userDetail.data.getUser
                ? userDetail.data.getUser.license
                : "trial",
              buckets: userDetail.data.getUser
                ? userDetail.data.getUser.buckets
                : [],
              invitations: userDetail.data.getUser
                ? userDetail.data.getUser.invitations
                : [],
            };
            // tracking
            mixpanel.people.set({
              $email: userObject ? userObject?.userEmail : userId, // only reserved properties need the $
              USER_ID: userObject ? userObject?.userId : userEmail, // use human-readable names
              USER_NAME: userObject?.name,
            });
            // initialize the user
            store.initializeStore(userObject);
          })
          .catch((error) => {
            console.log(error.message);
            alert("we found no user object for: " + userId);
          });
      })
      .catch((err) => {
        console.log("profile user: ", err);
        alert("login problems", err.message);
      });
  }

  getComponent(props) {
    if (store.bucketDataAvailable) {
      return <TheLayout {...props} />;
    } else {
      return <Redirect to="/welcome" />;
    }
  }

  render() {
    if (store.currentStatus === "loading") {
      return loading;
    }

    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route path="/welcome" component={WelcomeView} />
            <Route
              path="/"
              name="Home"
              render={(props) => this.getComponent(props)}
              // render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default withAuthenticator(observer(App));
