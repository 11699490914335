import { observable, decorate } from "mobx";
import { API, graphqlOperation } from "aws-amplify";
import { isBefore, addDays, parse } from "date-fns";
import { listPipelines as ListPipelines } from "../graphql/queries";
import {
  getNumberFromCurrency,
  getValueAsString,
  truncate,
} from "../utils/helper";
import { getFieldCaption } from "../utils/fields-info";
import { loadPipelineItemsFromStorage } from "../data/storageManager";
import { loadPipelineItemsFromLiveData } from "../data/livedataManager";
import { storeStatus, pipelineStatus, NOT_AVAILAVLE } from "../utils/constants";

// let globalListPipelineItems = [];
let globalListDealOwners = [];
// let globalListCountries = [];
// let globalListDealStages = [];
// let globalListIndustries = [];
// let globalListDealRanges = [];
// let globalListProbabilities = [];

class StorePipeline {
  pipelineSnapshots = [];
  storeStatus = storeStatus.nothing;
  actualBucketId = ""; //"bc0b025b-fea8-4b62-bc0c-0e711c825f1a";
  // globals
  newestPipelineId = "";
  priorPipelineId = "";
  detailDialogRecords = [];

  actualPipeline = {
    pipelineId: "",
    pipelineInfo: "actual",
    pipelineName: "",
    pipelineDate: "",
    pipelineItems: [],
    pipelineStatistics: [],
    pipelineValue: 0,
    pipelineValuePercentage: 0,
    listCountries: [],
    listDealStages: [],
    //listDealOwners: [],
    listDealRanges: [],
    listIndustries: [],
    listProbabilities: [],
    // status
    pipelineStatus: pipelineStatus.notProcessed,
  };

  livePipeline = {
    pipelineId: "",
    pipelineInfo: "actual",
    pipelineName: "",
    pipelineDate: "",
    pipelineItems: [],
    pipelineStatistics: [],
    pipelineValue: 0,
    pipelineValuePercentage: 0,
    listCountries: [],
    listDealStages: [],
    //listDealOwners: [],
    listDealRanges: [],
    listIndustries: [],
    listProbabilities: [],
    // status
    pipelineStatus: pipelineStatus.notProcessed,
  };

  comparePipeline = {
    pipelineId: "",
    pipelineInfo: "compare",
    pipelineName: "",
    pipelineDate: "",
    pipelineItems: [],
    // status
    pipelineStatus: pipelineStatus.notProcessed,
  };

  // import pipeline
  importedPipeline = {
    pipelineInfo: "imported",
    pipelineName: "",
    pipelineDate: "",
    pipelineFileName: "",
    pipelineItems: [],
    pipelineStatistics: [],
    // status
    pipelineStatus: pipelineStatus.notProcessed,
  };

  async initializeStore(userObject) {
    // get the buckets
    this.actualBucketId =
      userObject.buckets.length > 0 ? userObject.buckets[0] : "demo";
    // load the snapshots
    if (this.actualPipeline.pipelineStatus === pipelineStatus.notProcessed) {
      this.actualPipeline.pipelineStatus = pipelineStatus.loadingSnapshots;
      await this._getAvailableSnapshotsFromServer(this.actualBucketId)
        .then((pipelineId) => {
          console.log("newest pipeline id: " + this.newestPipelineId);
          // snapshot list loaded
          this.actualPipeline.pipelineStatus = pipelineStatus.snapshotsLoaded;
          this.actualPipeline.pipelineId = pipelineId;
          // load the latest pipeline
          this.loadActualPipeline();
        })
        .catch((err) => {
          console.log("INITIALIZE error pipeline loading: ", err);
          this.actualPipeline.pipelineStatus = pipelineStatus.error;
        });
    }
    return this.newestPipelineId;
  }

  get getPipelineStatus() {
    return this.actualPipeline.pipelineStatus;
  }

  get getActualBucketId() {
    return this.actualBucketId;
  }

  get getActualPipelineId() {
    return this.actualPipeline.pipelineId;
  }

  get getActualPipelineName() {
    return this.actualPipeline.pipelineName;
  }

  get getActualDealOwners() {
    return globalListDealOwners ? globalListDealOwners : [];
  }

  setDealOwnerList(list) {
    globalListDealOwners = [...list];
  }

  get getActualCountries() {
    let tempList = [];
    let i = 0;
    for (i = 0; i < this.actualPipeline.listCountries.length; i++) {
      tempList.push({
        name: this.actualPipeline.listCountries[i],
        value: 0,
        count: 0,
      });
    }
    return tempList;
  }

  getPipelineItemsFromList(items) {
    let tempList = items;
    if (tempList === undefined) {
      tempList = this.actualPipeline.pipelineItems;
    }
    return tempList;
  }

  getPipelineDataUrlFromId(pipelineId) {
    const pipeline_object = pipelineStore.pipelineSnapshots.filter((obj) => {
      return obj.id === pipelineId;
    });
    // read the data from the url
    return pipeline_object[0].url_data;
  }

  // DEAL OWNERS
  getDealOwnerIdFromName(dealOwner) {
    const items = this.getActualDealOwners;
    const index = items.findIndex((item) => item.name === dealOwner);
    if (index !== -1) {
      return index;
    }
  }

  getDealOwnerInfoFromId(dealOwnerId) {
    const items = this.getActualDealOwners;
    const index = dealOwnerId;
    if (index !== -1) {
      return {
        name: items[index].name,
        id: index,
        score: 3.2,
      };
    }
  }

  getDealOwnerNameFromIndex(index) {
    return this.globalListDealOwners[index]
      ? this.globalListDealOwners[index].name
      : NOT_AVAILAVLE;
  }

  // DETAIL DIALOG

  getDealInfoFromPipeline(pipelineId, dealId) {
    const items = this.actualPipeline.pipelineItems;
    const index = items.findIndex((item) => item.dealid === dealId);
    if (index !== -1) {
      return items[index];
    }
  }

  setDetailDialogData(type, detailid) {
    const tempRecord = this.getDealInfoFromPipeline("", detailid);

    let tempRecords = [];

    for (var key in tempRecord) {
      const tempName = key;
      let tempValue = tempRecord[key] ? tempRecord[key] : "--";

      if (tempValue !== "--") {
        tempRecords.push({
          name: tempName,
          caption: truncate(getFieldCaption(tempName), 20),
          value: getValueAsString(tempValue),
        });
      }
    }

    this.detailDialogRecords = tempRecords;
  }

  // SNAPSHOTS

  async loadActualPipeline() {
    if (this.actualPipeline.pipelineStatus === pipelineStatus.snapshotsLoaded) {
      // LOAD THE LATEST PIPELINE

      loadPipelineItemsFromStorage(
        this.newestPipelineId,
        this.actualPipeline,
        undefined
      )
        .then((tempId) => {
          console.log("loadPipelineItemsFromStorage loaded: ");
        })
        .catch((error) => {
          console.error("loadPipelineItemsFromStorage error", error);
          this.actualPipeline.pipelineStatus = pipelineStatus.error;
        });

      // get the data from salesforce
      loadPipelineItemsFromLiveData(
        this.newestPipelineId,
        this.livePipeline,
        undefined
      )
        .then((tempId) => {
          console.log("loadPipelineItemsFromLiveData loaded");
        })
        .catch((error) => {
          console.error("loadPipelineItemsFromLiveData error", error);
          this.actualPipeline.pipelineStatus = pipelineStatus.error;
        });
    }
  }

  // SERVER

  async _getAvailableSnapshotsFromServer(bucketId) {
    let tempNewestPipelineId = "";
    let tempPriorPipelineId = "";

    try {
      var tempList = [];
      var latestStartDate = undefined;
      const tempPipelines = await API.graphql(
        graphqlOperation(ListPipelines, {
          filter: {
            bucket_id: {
              eq: bucketId, //"bc0b025b-fea8-4b62-bc0c-0e711c825f1a"
            },
          },
        })
      );

      // get the max value
      let maxValue = 1;
      var i;
      for (i = 0; i < tempPipelines.data.listPipelines.items.length; i++) {
        const tempItem = tempPipelines.data.listPipelines.items[i];
        const tempNumber = getNumberFromCurrency(tempItem.value_mrr_weighted);
        if (!isNaN(tempNumber)) {
          if (tempNumber > maxValue) {
            maxValue = tempNumber;
          }
        }
      }

      for (i = 0; i < tempPipelines.data.listPipelines.items.length; i++) {
        const tempItem = tempPipelines.data.listPipelines.items[i];
        // get the latest pipeline
        if (latestStartDate === undefined) {
          latestStartDate = tempItem.snapshot_date;
          tempNewestPipelineId = tempItem.id;
        }
        if (latestStartDate < tempItem.snapshot_date) {
          latestStartDate = tempItem.snapshot_date;
          tempNewestPipelineId = tempItem.id;
        }

        const tempDealOwners = tempItem.dealowner_count;
        const tempValueMrrPerDealOwner =
          tempDealOwners > 0
            ? Math.round(
                getNumberFromCurrency(tempItem.value_mrr_total) / tempDealOwners
              )
            : 0;
        const tempValuePerDealOwner =
          tempDealOwners > 0
            ? Math.round(
                getNumberFromCurrency(tempItem.value_total) / tempDealOwners
              )
            : 0;

        tempList.push({
          id: tempPipelines.data.listPipelines.items[i].id,
          name: tempPipelines.data.listPipelines.items[i].name,
          description: tempPipelines.data.listPipelines.items[i].description,
          snapshot_date:
            tempPipelines.data.listPipelines.items[i].snapshot_date,
          snapshot_source:
            tempPipelines.data.listPipelines.items[i].snapshot_source,
          url_filename: tempPipelines.data.listPipelines.items[i].url_filename,
          url_data: tempPipelines.data.listPipelines.items[i].url_data,
          value_total: tempPipelines.data.listPipelines.items[i].value_total,
          value_weighted:
            tempPipelines.data.listPipelines.items[i].value_weighted,
          value_won: tempPipelines.data.listPipelines.items[i].value_won,
          value_churned:
            tempPipelines.data.listPipelines.items[i].value_churned,
          value_average:
            tempPipelines.data.listPipelines.items[i].value_average,
          value_per_dealowner: tempValuePerDealOwner,

          value_mrr_total:
            tempPipelines.data.listPipelines.items[i].value_mrr_total,
          value_mrr_weighted:
            tempPipelines.data.listPipelines.items[i].value_mrr_weighted,
          value_mrr_won:
            tempPipelines.data.listPipelines.items[i].value_mrr_won,
          value_mrr_churned:
            tempPipelines.data.listPipelines.items[i].value_mrr_churned,
          value_mrr_average:
            tempPipelines.data.listPipelines.items[i].value_mrr_average,
          value_mrr_per_dealowner: tempValueMrrPerDealOwner,
          smell_count_lastactivity:
            tempPipelines.data.listPipelines.items[i].smells_count,
          dealowner_count:
            tempPipelines.data.listPipelines.items[i].dealowner_count,
          activedeals_count:
            tempPipelines.data.listPipelines.items[i].activedeals_count,
          record_count: tempPipelines.data.listPipelines.items[i].record_count,

          score: tempPipelines.data.listPipelines.items[i].score,
          pipeline_percentage:
            getNumberFromCurrency(
              tempPipelines.data.listPipelines.items[i].value_mrr_weighted
            ) / maxValue,
        });
      }
      // sort by start date
      tempList = tempList.sort((b, a) =>
        a.snapshot_date.localeCompare(b.snapshot_date)
      );

      if (tempList) this.pipelineSnapshots = tempList;
      if (tempNewestPipelineId) this.newestPipelineId = tempNewestPipelineId;

      //prior pipelineId
      tempPriorPipelineId = this._getPipelineIdForSpecificDate(addDays(new Date(), -7), tempList)
      if (tempPriorPipelineId) this.priorPipelineId = tempPriorPipelineId;
    } catch (err) {
      console.log("getAvailablePipelinesFromServer error: ", err);
    }
    return tempNewestPipelineId;
  }

  _getPipelineIdForSpecificDate(date, list) {
    let tempPipelineId = undefined;
    let tempList = list.sort((b, a) =>
      a.snapshot_date?.localeCompare(b.snapshot_date)
    );

    var i; // get the last pipeline
    for (i = 0; i < tempList.length; i++) {
      const tempItem = tempList[i];
      const tempSnapshotDate = new Date(tempItem.snapshot_date);
      // get the latest pipeline
      if (isBefore(tempSnapshotDate, date)) {
        tempPipelineId = tempItem.id;
        break;
      }
    }
    return tempPipelineId;
  }
}

decorate(StorePipeline, {
  // pipelines
  actualPipeline: observable,
  importedPipeline: observable,
  pipelineSnapshots: observable,
  detailDialogRecords: observable,
});

const pipelineStore = new StorePipeline();

export default pipelineStore;
