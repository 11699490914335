import { pipelineStatus } from "../utils/constants";
import { MapperFromSalesForce } from "../imports/MapperFromSalesForce";
import { enrichDealRecord } from "../imports/enrichtment-helper";
import { calculatePipelineStatistics } from "../analytics/analyticsForPipeline";
// import pipelineStore from "../store/StorePipeline";

export async function loadPipelineItemsFromLiveData(
  pipelineId,
  pipelineObject,
  callback
) {
  try {
    // get the pipeline data
    pipelineObject.pipelineName = "live data: ";
    pipelineObject.pipelineDate = new Date();
    // const url = "https://pipetrend-sfbridge.herokuapp.com/getdeals";
    const url = "http://localhost:8675/getdeals";

    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          this.handleResponseError(response);
        }
        return response.json();
      })
      .then((json) => {
        console.log("Retrieved items:");
        console.log(json);
        const tempList = [];
        const itemArray = json;
        for (var i = 0; i < itemArray.length; i++) {
          let tempDeal = new MapperFromSalesForce(itemArray[i]);
          let enrichedDeal = enrichDealRecord(tempDeal);
          tempList.push(enrichedDeal);
        }
        // store it
        pipelineObject.pipelineItems = tempList;
        pipelineObject.pipelineStatus = pipelineStatus.itemsLoaded;
        // get the statistics
        // calculatePipelineStatistics(pipelineId, pipelineObject, callback)
        //   .then((pipelineId) => {
        //     // snapshot list loaded
        //     pipelineObject.pipelineStatus = pipelineStatus.ready;
        //   })
        //   .catch((err) => {
        //     console.log("LOADING error pipeline loading: ", err);
        //     return pipelineStatus.error;
        //   });
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  } catch (err) {
    console.log("error", err);
    callback([]);
  }

  //   fetch(url, { method: "GET" })
  //     .then((res) => {
  //       console.log("res", res);
  //       res.json();
  //     })
  //     .then((out) => {
  //       var i;
  //       const listLength = out.length;
  //       var tempList = [];
  //       for (i = 0; i < listLength; i++) {
  //         let tempDeal = new MapperFromSalesForce(out[i]);
  //         // enrichDealRecord(tempDeal);
  //         tempList.push(tempDeal);
  //       }
  //       // store it
  //       pipelineObject.pipelineItems = tempList;
  //       pipelineObject.pipelineStatus = pipelineStatus.itemsLoaded;
  //       // get the statistics
  //       calculatePipelineStatistics(pipelineId, pipelineObject, callback)
  //         .then((pipelineId) => {
  //           // snapshot list loaded
  //           pipelineObject.pipelineStatus = pipelineStatus.ready;
  //         })
  //         .catch((err) => {
  //           console.log("LOADING error pipeline loading: ", err);
  //           return pipelineStatus.error;
  //         });
  //     })
  //     .catch((err) => {
  //       throw err;
  //     });
  // } catch (err) {
  //   console.log("error", err);
  //   callback([]);
  // }
}
