import React, { useState } from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import { Empty } from "antd";
import { ElementHeader } from "./elementcomponents/ElementHeader";
import { InvitationGrid } from "../components/InvitationsGrid";
import store from "../store/StoreMain";

export function ElementInvitationsGrid(props) {
  const [visibleState, setVisibleState] = useState(
    store.getLocalStoreValue("ElementInvitationsGrid", true)
  );

  let contents = (
    <>
      <Card>
        <CardHeader>
          <ElementHeader
            caption={props.caption}
            setvisiblestate={setVisibleState}
          />
        </CardHeader>
        {visibleState && (
          <>
            <CardBody>
              {props.items && props.items?.length > 0 && (
                <InvitationGrid items={props.items} />
              )}
              {(!props.items || props.items?.count === 0) && (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </CardBody>
          </>
        )}
      </Card>
    </>
  );

  return <div>{contents}</div>;
}

export default ElementInvitationsGrid;
