import uuid from "uuid/v4";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { createPipeline as CreatePipeline } from "../graphql/mutations";
import config from "../aws-exports";
import { pipelineStatus } from "../utils/constants";
import { MapperFromStorage } from "../imports/MapperFromStorage";
import { calculatePipelineStatistics } from "../analytics/analyticsForPipeline";
import pipelineStore from "../store/StorePipeline";

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;

function _findObjectByKey(array, key, value) {
  for (var i = 0; i < array.length; i++) {
    if (array[i][key] === value) {
      return array[i].value;
    }
  }
  return 0;
}

// NEW
export async function createNewSnapshotInStorage(pipelineName, importedPipeline) {
  const newfile = importedPipeline.pipelineFileName;
  const actualBucketId = pipelineStore.getActualBucketId;
  if (newfile) {
    const extension = newfile.name.split(".")[1];
    const { type: mimeType } = newfile;
    const pipeline_data = JSON.stringify(importedPipeline.pipelineItems);

    const key_filename = `imports/${actualBucketId}/${uuid()}${pipelineName}.${extension}`;
    const key_data = `pipelines/${actualBucketId}/${uuid()}${pipelineName}.json`;
    const url_filename = `https://${bucket}.s3.${region}.amazonaws.com/public/${key_filename}`;
    const url_data = `https://${bucket}.s3.${region}.amazonaws.com/public/${key_data}`;

    // const key_filename = `${actualBucketId}/imports/${uuid()}${pipelineName}.${extension}`;
    // const key_data = `${actualBucketId}/pipelines/${uuid()}${pipelineName}.json`;
    // const url_filename = `https://${bucket}.s3.${region}.amazonaws.com/buckets/${key_filename}`;
    // const url_data = `https://${bucket}.s3.${region}.amazonaws.com/buckets/${key_data}`;


    const records_count = importedPipeline.pipelineItems.length;
    const pipelineStatistics = importedPipeline.pipelineStatistics;

    const inputData = {
      bucket_id: actualBucketId,
      name: pipelineName,
      url_filename: url_filename,
      url_data: url_data,
      record_count: records_count,
      activedeals_count: _findObjectByKey(
        pipelineStatistics,
        "key",
        "activedeals_count"
      ),
      smells_count: _findObjectByKey(
        pipelineStatistics,
        "key",
        "smell_count_lastactivity"
      ),
      dealowner_count: _findObjectByKey(
        pipelineStatistics,
        "key",
        "dealowner_count"
      ),
      value_total: _findObjectByKey(
        pipelineStatistics,
        "key",
        "value_amount_total"
      ),
      value_weighted: _findObjectByKey(
        pipelineStatistics,
        "key",
        "value_amount_weighted"
      ),
      value_average: _findObjectByKey(
        pipelineStatistics,
        "key",
        "value_amount_average"
      ),
      value_churned: _findObjectByKey(
        pipelineStatistics,
        "key",
        "value_amount_churned"
      ),
      value_won: _findObjectByKey(
        pipelineStatistics,
        "key",
        "value_amount_won"
      ),
      value_mrr_total: _findObjectByKey(
        pipelineStatistics,
        "key",
        "value_mrr_total"
      ),
      value_mrr_weighted: _findObjectByKey(
        pipelineStatistics,
        "key",
        "value_mrr_weighted"
      ),
      value_mrr_average: _findObjectByKey(
        pipelineStatistics,
        "key",
        "value_mrr_average"
      ),
      value_mrr_won: _findObjectByKey(
        pipelineStatistics,
        "key",
        "value_mrr_won"
      ),
      value_mrr_churned: _findObjectByKey(
        pipelineStatistics,
        "key",
        "value_mrr_churned"
      ),
      score: _findObjectByKey(pipelineStatistics, "key", "score"),
    };

    try {
      let errorOccured = false;
      let errorMessage = "";

      await Storage.put(key_data, pipeline_data, {
        contentType: "text/plain",
      })
        .then((response) => {
          console.log("storage put: ", response);
        })
        .catch((err) => {
          console.log("storage error: ", err);
          errorOccured = true;
          errorMessage = err.message;
        });
  
        await Storage.put(key_filename, newfile, {
        contentType: mimeType,
      })
        .then((response) => {
          console.log("storage put: ", response);
        })
        .catch((err) => {
          console.log("storage error: ", err);
          errorOccured = true;
          errorMessage = errorMessage + err.message;
        });

      // bug
      // errorOccured = false;

      if (errorOccured === false) {
        await API.graphql(
          graphqlOperation(CreatePipeline, { input: inputData })
        );
      } else {
        alert("error while uploading: " + errorMessage);
      }
    } catch (err) {
      console.log("error: ", err);
      alert(err.message);
    }
  }
}

export async function loadPipelineItemsFromStorage(pipelineId, pipelineObject, callback) {
  // hack for refreshs
  if (pipelineStore.pipelineSnapshots.length === 0) {
    await pipelineStore._getAvailableSnapshotsFromServer(this.actualBucketId);
  }

  if (pipelineObject.pipelineItems.length === 0) {
    try {
      // get the pipeline data
      const pipeline_object = pipelineStore.pipelineSnapshots.filter((obj) => {
        return obj.id === pipelineId;
      });
      pipelineObject.pipelineName = pipeline_object.pipelineName;
      // read the data from the url
      let url = pipeline_object[0].url_data;
      pipelineObject.pipelineName = pipeline_object[0].name.toUpperCase();
      pipelineObject.pipelineDate = pipeline_object[0].snapshot_date;

      fetch(url)
        .then((res) => res.json())
        .then((out) => {
          var i;
          const listLength = out.length;
          var tempList = [];
          for (i = 0; i < listLength; i++) {
            let tempDeal = new MapperFromStorage(out[i]);
            // enrichDealRecord(tempDeal);
            tempList.push(tempDeal);
          }
          // store it
          pipelineObject.pipelineItems = tempList;
          pipelineObject.pipelineStatus = pipelineStatus.itemsLoaded;
          // get the statistics
          calculatePipelineStatistics(pipelineId, pipelineObject, callback)
            .then((pipelineId) => {
              // snapshot list loaded
              pipelineObject.pipelineStatus = pipelineStatus.ready;
            })
            .catch((err) => {
              console.log("LOADING error pipeline loading: ", err);
              return pipelineStatus.error;
            });
        })
        .catch((err) => {
          throw err;
        });
    } catch (err) {
      console.log("error", err);
      callback([]);
    }
  } else {
    callback(pipelineObject);
  }
}
