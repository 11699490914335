import {
  DEALSTAGE_CLOSED_WON,
  DEALSTAGE_CLOSED_LOST,
  DEALSTAGE_CLOSED_CHURNED,
} from "../utils/constants.js";
import { differenceInMonths, isDate } from "date-fns";

// DATES

export function getDifferenceInMonths(start, end) {
  let tempStart = new Date(start);
  let tempEnd = new Date(end);

  if (isDate(tempStart) && isDate(tempEnd)) {
    let tempResult = differenceInMonths(tempStart, tempEnd);
    return tempResult;
  }
  return 0;
}

// NUMBERS

export function getMRRRange(value) {
  let tempValue = "0 ( - 200)";
  if (value > 10000) {
    tempValue = "7 (10K - )";
  } else if (value > 5000) {
    tempValue = "6 (5K - 10K)";
  } else if (value > 3000) {
    tempValue = "5 (3K - 5K)";
  } else if (value > 2000) {
    tempValue = "4 (2K - 3K)";
  } else if (value > 1000) {
    tempValue = "3 (1K - 2K)";
  } else if (value > 500) {
    tempValue = "2 (500 - 1K)";
  } else if (value > 200) {
    tempValue = "1 (200 - 500)";
  }
  return tempValue;
}

export function getSalesCycleRange(value) {
  let tempValue = "0 (0 - 1)";
  if (value > 15) {
    tempValue = "6 (> 15)";
  } else if (value > 12) {
    tempValue = "5 (13 - 15)";
  } else if (value > 9) {
    tempValue = "4 (10 - 12)";
  } else if (value > 6) {
    tempValue = "3 (7 - 9)";
  } else if (value > 3) {
    tempValue = "2 (4 - 6)";
  } else if (value > 1) {
    tempValue = "1 (2 - 3)";
  }
  return tempValue;
}

export function getAgeRange(value) {
  let tempValue = "0 (0 - 1)";
  if (value > 15) {
    tempValue = "6 (> 15)";
  } else if (value > 12) {
    tempValue = "5 (13 - 15)";
  } else if (value > 9) {
    tempValue = "4 (10 - 12)";
  } else if (value > 6) {
    tempValue = "3 (7 - 9)";
  } else if (value > 3) {
    tempValue = "2 (4 - 6)";
  } else if (value > 1) {
    tempValue = "1 (2 - 3)";
  }
  return tempValue;
}

export function getProbabilityByDealStage(value) {
  let tempValue = 0;
  if (value === "Appointment set") {
    tempValue = 0.08;
  } else if (value === "Feasibility Check/PoC") {
    tempValue = 0.19;
  } else if (value === "Use Case Definition") {
    tempValue = 0.1;
  } else if (value === "Offer Sent") {
    tempValue = 0.45;
  } else if (value === "Procurement") {
    tempValue = 0.8;
  } else if (value === DEALSTAGE_CLOSED_WON) {
    tempValue = 1;
  }
  return tempValue;
}

export function getIsActiveByDealStage(value) {
  let tempValue = true;
  if (value === DEALSTAGE_CLOSED_WON) {
    tempValue = false;
  } else if (value === DEALSTAGE_CLOSED_LOST) {
    tempValue = false;
  } else if (value === DEALSTAGE_CLOSED_CHURNED) {
    tempValue = false;
  }
  return tempValue;
}

export function getIsWonByDealStage(value) {
  let tempValue = false;
  if (value === DEALSTAGE_CLOSED_WON) {
    tempValue = true;
  }
  return tempValue;
}

export function getValuePercentage(value, percentage) {
  return Math.round(value * percentage);
}
