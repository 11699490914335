export const PATH_SEARCH = "/search";
export const PATH_GETVALUE = "/getvalue";
export const DURATION_VALUEANIMATION = 600;
export const DURATION_VALUEANIMATION_SHORT = 300;
export const USER_LOCALE = "de";
export const NOT_AVAILAVLE = "N/A";

// colors
export const COLOR_BRANDBACKGROUND = "#0078D4";
export const COLOR_HIGHLIGHTBLUE = "#26a6d7";
export const DEALSTAGE_CLOSED_WON = "Closed Won";
export const DEALSTAGE_CLOSED_LOST = "Closed Lost";
export const DEALSTAGE_CLOSED_CHURNED = "Churned";

export const VALUE_ARR = "value_arr";
export const VALUE_MRR = "value_mrr";
export const VALUE_MRR_PERCENTAGE = "value_mrr_percentage";
export const VALUE_AMOUNT = "value_amount";

export const STRIPE_PRO_SINGLE = "prod_HYBlTz9ei3XbSU";
export const STRIPE_PRO_SINGLE_MONTHLY_EUR_PRICEID = "price_1Gz5NVEtfeKfM3OP5jeQzAh8";
export const STRIPE_PRO_SINGLE_YEARLY_EUR_PRICEID = "price_1Gz5QCEtfeKfM3OPJJpSicb4";

export const metricCardType = {
  compact: 1,
  withlinechart: 2,
  withbarchart: 4,
  standalone: 8
};

export const storeStatus = {
  nothing: 1,
  userloggedin: 2,
  bucketavailable: 4,
  pipelineavailable: 8,
  pipelineloaded: 16
};

export const pipelineStatus = {
  notProcessed: 1,
  loadingSnapshots: 2,
  snapshotsLoaded: 4,
  loadingItems: 8,
  itemsLoaded: 16,
  loadingStatistics: 32,
  statisticsLoaded: 64,
  ready: 128,
  error: 256
};
