import React, { useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import LoadingAnimation from "./LoadingAnimation";
import DataGrid, {
  Column,
  Paging,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import "./InvitationsGrid.css";

export function InvitationGrid(props) {
  const [valueItems, setValueItems] = useState([]);
  const animationprops = useSpring({ opacity: 1, from: { opacity: 0 } });

  // get data
  useEffect(() => {
    setValueItems(props.items);
  }, [props]);

  function rowClick(data) {
    const bucketId = data?.data?.bucket_id;
    const bucketName = data?.data?.bucket_name;
    
    alert("accept invitation? " + bucketName);
    // const elementType = "deal";
    // const elementId = data.key; //data?.dealid;
    // store.showElementDetailPanel(elementType, elementId);
  }

  function renderGridCell(data) {
    return (
      <a
        bucketid={data.row.data.bucket_id}
        onClick={(bucketid) => rowClick(data)}
      >
        {data.text}
      </a>
    );
  }

  var getGrid = (
    <animated.div style={animationprops}>
      <DataGrid
        id="gridContainer"
        dataSource={valueItems}
        keyExpr="bucket_id"
        columnHidingEnabled={false}
        showBorders={false}
        showColumnLines={false}
        showRowLines={false}
        rowAlternationEnabled={true}
      >
        <Paging defaultPageSize={10} />
        <SortByGroupSummaryInfo summaryItem="count" />
        <Column
          dataField="bucket_name"
          caption="Bucket Name"
          cellRender={renderGridCell}
          cssClass="namefield"
        />
        <Column dataField="sender_name" caption="Invited by" alignment="left" />
        <Column dataField="status" caption="Status" alignment="left" />
      </DataGrid>
    </animated.div>
  );

  let contents = !valueItems ? (
    <LoadingAnimation />
  ) : (
    <div id="grid">{getGrid}</div>
  );
  return <div>{contents}</div>;
}

export default InvitationGrid;
