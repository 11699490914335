export const fieldInfo = {
  dealid: "Deal ID",
  dealname: "Deal Name",
  dealowner: "Owner",
  dealstage: "Stage",
  closedate: "Close Date",
  closedate_month: "Close Month",
  lostdate: "Lost Date",
  churndate: "Churn Date",
  value_mrr_range: "MRR Range",
  country: "Country",
  probability: "Probability",
  dealage_inmonths: "Deal Age",
  closedate_inmonths: "Close Date Months",
  estimatedsalescylce_inmonths: "Sales Cycle (est)",
  source: "Source",
  partner: "Partner",
  category: "Category",
  createdate: "Create Date",
  lastactivitydate: "Last Activity",
  lastmodifieddate: "Last Modified",
  value_arr: "ARR",
  value_mrr: "MRR",
  value_mrr_percentage: "MRR %",
  value_amount: "Amount",
  customerid: "Customer ID",
  customername: "Customer",
  customerindustry: "Industry",
  customeremployees: "Employees",
  customerrating: "Rating",
  customercity: "City",
  customerwebsite: "Website",
  createdate_month: "Create Month",
  value_amount_percentage: "Amount %",
  estimatedsalescylce_range: "Sales Cycle Range",
  dealage_range: "Age Range",
  notes: "Notes",
  type: "Type",
  rating: "Rating",
  isactive: "Active",
  score: "Score",
  status: "Status",
  name: "Name",
  lastactivitydate_inmonths: "Last Activity Months",
  deal_url: "Deal",
  customer_url: "Account"
};

export function getFieldCaption(field) {
  let tempCaption = fieldInfo[field];
  if (!tempCaption) {
    tempCaption = field;
  }
  return tempCaption;
}
