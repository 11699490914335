import { observable, decorate, computed } from "mobx";
import mixpanel from "mixpanel-browser";
import { isIOS, isMobileOnly } from "react-device-detect";
import { searchObjects } from "../analytics/analytics";
import { VALUE_MRR } from "../utils/constants";
import localStorage from "mobx-localstorage";
import pipelineStore from "./StorePipeline";

class StoreMain {
  // environment
  currentUser = null;
  currentStatus = "loading";
  bucketDataAvailable = false;
  isIOS = isIOS;
  isPhone = isMobileOnly;

  colorPrimary = "#517fe2";
  colorPositive = "#5ec198";
  colorNegative = "#ff5757";
  colorNegative2 = "#f9cad7";
  colorNeutral = "gray";

  // OPTIONS

  chartPalette = [
    "#5165c9",
    "#8580e3",
    "#4ab9f3",
    "#5fe0dd",
    "#a5f5b4",
    "#fad9b1",
    "#fb8890",

    "#515dc1",
    "#3f8ec9",
    "#48b0a8",
    "#4ee0b5",
    "#ba8e13",
    "#b0a3a8",
    "#bf4c54",

    "#b3b7fa",
    "#c7ecfc",
    "#bef7f2",
    "#d5f5a7",
    "#ffef99",
    "#fee5bc",
    "#fecbd1",
  ];

  scenarioEntries = [
    {
      Menu_ID: 1,
      Menu_Subject: "Default View",
      Menu_Store: "Scenarios",
      Menu_Priority: 2,
      Menu_Parent: "pivot_default",
      Menu_Author: "Stephan Methner",
    },
    {
      Menu_ID: 2,
      Menu_Subject: "Existing MRR by Country",
      Menu_Store: "Scenarios",
      Menu_Priority: 1,
      Menu_Parent: "pivot_default",
      Menu_Author: "Stephan Methner",
    },
    {
      Menu_ID: 3,
      Menu_Subject: "Active Pipeline by Country",
      Menu_Store: "Scenarios",
      Menu_Priority: 1,
      Menu_Parent: "pivot_default",
      Menu_Author: "Stephan Methner",
    },
    {
      Menu_ID: 4,
      Menu_Subject: "Pipeline by Deal Owners and Stages",
      Menu_Store: "Scenarios",
      Menu_Priority: 1,
      Menu_Parent: "pivot_default",
      Menu_Author: "Stephan Methner",
    },
    {
      Menu_ID: 5,
      Menu_Subject: "Pipeline with likely Personal Rating",
      Menu_Store: "Scenarios",
      Menu_Priority: 1,
      Menu_Parent: "pivot_default",
      Menu_Author: "Stephan Methner",
    },
    {
      Menu_ID: 6,
      Menu_Subject: "Team Performance",
      Menu_Store: "Team",
      Menu_Priority: 4,
      Menu_Parent: "pivot_default",
      Menu_Author: "Stephan Methner",
    },
    {
      Menu_ID: 7,
      Menu_Subject: "Sales Cycle Analysis",
      Menu_Store: "Store",
      Menu_Priority: 3,
      Menu_Parent: "pivot_default",
      Menu_Author: "Angelika Methner",
    },
  ];

  elementsByArea = [
    {
      Element_ID: 1,
      Element_Subject: "Changes",
      Element_Area: "Dashboard",
      Element_Type: 4,
    },
    {
      Element_ID: 2,
      Element_Subject: "Weighted MRR",
      Element_Area: "Dashboard",
      Element_Type: 4,
    },
    {
      Element_ID: 3,
      Element_Subject: "Won and Pipeline MRR %",
      Element_Area: "Dashboard",
      Element_Type: 3,
    },
    {
      Element_ID: 4,
      Element_Subject: "Won MRR (Existing/New)",
      Element_Area: "Dashboard",
      Element_Type: 3,
    },
    {
      Element_ID: 5,
      Element_Subject: "Pipeline Deals (by Size)",
      Element_Area: "Dashboard",
      Element_Type: 3,
    },
    {
      Element_ID: 6,
      Element_Subject: "Pipeline Journey",
      Element_Area: "Pipeline Journey",
      Element_Type: 3,
    },
    {
      Element_ID: 16,
      Element_Subject: "Deal Owners",
      Element_Area: "Deal Owners",
      Element_Type: 3,
    },
    {
      Element_ID: 7,
      Element_Subject: "Won Deals",
      Element_Area: "Deal Owners",
      Element_Type: 3,
    },
    {
      Element_ID: 17,
      Element_Subject: "Deal Owners Pivot",
      Element_Area: "Deal Owners",
      Element_Type: 3,
    },
    {
      Element_ID: 10,
      Element_Subject: "Won MRR (Existing/New)",
      Element_Area: "Deal Analytics",
      Element_Type: 3,
    },
    {
      Element_ID: 11,
      Element_Subject: "Won vs. Lost Deals",
      Element_Area: "Deal Analytics",
      Element_Type: 3,
    },
    {
      Element_ID: 8,
      Element_Subject: "Weighted MRR by Sales Cycle",
      Element_Area: "Deal Analytics",
      Element_Type: 3,
    },
    {
      Element_ID: 9,
      Element_Subject: "Weighted MRR by Deal Age",
      Element_Area: "Deal Analytics",
      Element_Type: 3,
    },
    {
      Element_ID: 12,
      Element_Subject: "Won Deals (by Size)",
      Element_Area: "Deal Analytics",
      Element_Type: 3,
    },
    {
      Element_ID: 13,
      Element_Subject: "Customers by Countries (MRR)",
      Element_Area: "Regions",
      Element_Type: 4,
    },
    {
      Element_ID: 14,
      Element_Subject: "Pipeline Deals by Countries (MRR)",
      Element_Area: "Regions",
      Element_Type: 2,
    },
    {
      Element_ID: 15,
      Element_Subject: "Countries (by MRR)",
      Element_Area: "Regions",
      Element_Type: 2,
    },
  ];

  chartExportsAvailable = false;

  invitationsCount = 0;
  // showBranding = false;
  // productName = "pipetrend";
  sidebarDefaultCollapsed = false;
  trialModeToolbarVisible = false;
  optionsDarkMode = false;
  optionsAsidePanelOpen = false;
  optionsSidebarOpen = "responsive";
  optionsValueHeaderVisible = true;

  chartDefaultHeight = this.isPhone ? 400 : 500;
  chartMainHead = this.isPhone ? 450 : 550;
  chartTreeMapHeight = 600;
  mapDefaultHeight = 700;

  // sizes
  historyMonthCount = 9;
  monthFormatShort = "yyyy/MM";

  // variables
  valueDefault = VALUE_MRR;

  detailPanelOpen = false;
  detailPanelElementId = "";

  globalSearchResults = observable([]);

  initializeStore(userObject) {
    this.currentUser = {
      id: userObject.id,
      name: userObject.name,
      avatar: userObject.avatar,
      email: userObject.email,
      license: userObject.license,
      buckets: userObject.buckets ? userObject.buckets : [],
      invitations: userObject.invitations?.items,
    };
    this.bucketDataAvailable = userObject.buckets?.length > 0;
    this.invitationsCount = this.currentUser.invitations?.length;
    this.trialModeToolbarVisible = this.currentUser?.license !== "single";
    //pipeline store
    pipelineStore.initializeStore(this.currentUser);
    this.currentStatus = "loaded";
  }

  get getUserID() {
    return this.currentUser ? this.currentUser.id : "";
  }

  get getUserName() {
    return this.currentUser ? this.currentUser.name : "";
  }

  get getUserAvatar() {
    const avatar = this.currentUser ? this.currentUser.avatar : "anonymous.png";
    return "assets/avatars/" + avatar;
  }

  get getUserInvitations() {
    return this.currentUser.invitations;
  }

  get getGlobalSearchResultsCount() {
    return this.globalSearchResults.length;
  }

  toggleDarkMode() {
    this.optionsDarkMode = !this.optionsDarkMode;
  }

  toggleDetailPanel() {
    this.detailPanelOpen = !this.detailPanelOpen;
  }

  toggleAsidePanel() {
    this.optionsAsidePanelOpen = !this.optionsAsidePanelOpen;
  }

  toggleSidebar(val) {
    this.optionsSidebarOpen = val;
  }

  showElementDetailPanel(elementType, elementId) {
    pipelineStore.setDetailDialogData(elementType, elementId);
    this.detailPanelOpen = true;
    // tracking
    mixpanel.track("showElementDetailPanel", {
      elementType: elementType,
      elementId: elementId,
    });
  }

  getLocalStoreValue(name, defaultValue) {
    let tempValue = localStorage.getItem(name);
    return tempValue ? tempValue : defaultValue;
  }

  setLocalStoreValue(name, actualValue) {
    localStorage.setItem(name, actualValue);
  }

  async getGlobalSearchResultItems(content) {
    // set the new search key
    const tempList = searchObjects(content);
    this.globalSearchResults.replace(tempList);
  }

  async setGlobalSearchKey(searchKey) {
    if (searchKey.length > 2) {
      this.getGlobalSearchResultItems(searchKey);
      // tracking
      mixpanel.track("setGlobalSearchKey", { search: searchKey });
    } else {
      this.globalSearchResults.clear();
    }
  }

  getMenuEntriesForParent(parentID) {
    return this.scenarioEntries;
  }
}

decorate(StoreMain, {
  bucketDataAvailable: observable,
  detailPanelOpen: observable,
  optionsAsidePanelOpen: observable,
  optionsSidebarOpen: observable,
  optionsValueHeaderVisible: observable,
  globalSearchResults: observable,
  optionsDarkMode: observable,
  currentStatus: observable,
  invitationsCount: observable,
  getGlobalSearchResultsCount: computed,
});

const store = new StoreMain();

export default store;

// chartPaletteBisher = [
//   "#517fe2",
//   "#94a3fa",
//   "#8ccbfa",
//   "#beeae7",
//   "#19afaf",
//   "#43c29b",
//   "#4550a6",
//   "#4c6476",
//   "#52b9cb",
//   "#377981",
//   "#fa472d",
// ];

// chartPaletteOld = [
//   "#5cbae6",
//   "#b6d857",
//   "#fac364",
//   "#8bd2ff",
//   "#d997cb",
//   "#f1d148",
//   "#93b8c5",
//   "#ccc5a7",
//   "#52b9cb",
//   "#dada46",
//   "#97a9fa",
// ];

// chartPalette2 = [
//   "#5899da",
//   "#e7733a",
//   "#ec497b",
//   "#18a878",
//   "#945ece",
//   "#12a4b3",
//   "#525df3",
//   "#be389d",
//   "#6b8892",
//   "#ee6768",
//   "#2e6496",
// ];

// chartPalette3 = [
//   "#93beeb",
//   "#f5a985",
//   "#66c1a2",
//   "#f38fad",
//   "#bd9ce1",
//   "#5dc2cd",
//   "#828af7",
//   "#d775c0",
//   "#acbbc3",
//   "#f7a5a5",
//   "#698bae",
// ];

// chartPalette4 = ["#ef476f", "#ffd166", "#06d6a0", "#118ab2", "#073b4c"];

// chartPalette5 = ["#e63946", "#f1faee", "#a8dadc", "#457b9d", "#1d3557"];

// chartPalette = [
//   "#8480e2",
//   "#3ab4f2",
//   "#60e0de",
//   "#a5f5b4",
//   "#f4bf1d",
//   "#ecbe9b",
//   "#fe8c93",

//   "#515dc1",
//   "#3f8ec9",
//   "#48b0a8",
//   "#4ee0b5",
//   "#ba8e13",
//   "#b0a3a8",
//   "#bf4c54",

//   "#b3b7fa",
//   "#c7ecfc",
//   "#bef7f2",
//   "#d5f5a7",
//   "#ffef99",
//   "#fee5bc",
//   "#fecbd1",
// ];
