import { format, differenceInMonths } from "date-fns";
import { getMonthValue } from "../utils/helper.js";
import {
  DEALSTAGE_CLOSED_WON,
  DEALSTAGE_CLOSED_LOST,
  DEALSTAGE_CLOSED_CHURNED,
  VALUE_MRR,
  // VALUE_MRR_PERCENTAGE,
  // VALUE_AMOUNT
} from "../utils/constants.js";
import pipelineStore from "../store/StorePipeline";
import store from "../store/StoreMain";

export function searchDealObjects(searchkey) {
  var searchKey = searchkey.toLowerCase();
  try {
    const pipelineItems = pipelineStore.actualPipeline.pipelineItems;
    var filtered = [];
    for (var i = 0; i < pipelineItems.length; i++) {
      const tempItem = pipelineItems[i];
      const tempName = tempItem.name.toLowerCase();
      if (tempName.includes(searchKey)) {
        filtered.push({
          dealid: tempItem.dealid,
          type: "deal",
          name: tempItem.name,
          dealstage: tempItem.dealstage,
          sortinfo: tempItem.dealstage,
          owner: tempItem.dealowner,
          closedate: tempItem.closedate,
          value: tempItem.value_mrr,
        });
      }
    }
    return filtered;
  } catch (err) {
    console.log("filter data", err);
    return [];
  }
}

export function getDealShortInfo(dealId, valueField) {
  let item = pipelineStore.getDealInfoFromPipeline("", dealId);
  let tempValueField = valueField ? valueField : VALUE_MRR;

  if (item) {
    const name = item.name;
    const value = item[tempValueField];
    const dealOwner = item.dealowner;
    const dealOwnerId = pipelineStore.getDealOwnerIdFromName(dealOwner);
    const dealStage = item.dealstage;
    const score = item.score;

    return {
      name: name,
      value: value,
      dealOwner: dealOwner,
      dealOwnerId: dealOwnerId,
      dealStage: dealStage,
      score: score,
      id: item.dealid
    };
  }
}

export function getActiveDealsByMonthOwner(items, valueField) {
  const tempList = [];
  let sourceList = pipelineStore.getPipelineItemsFromList(items);
  let tempValueField = valueField ? valueField : VALUE_MRR;

  var i;
  for (i = 0; i < sourceList.length; i++) {
    const tempItem = sourceList[i];
    if (tempItem.value_mrr_percentage > 0 && tempItem.isactive) {
      tempList.push({
        seriesname: tempItem.dealowner,
        value: tempItem[tempValueField],
        // value: tempItem.value_mrr_percentage,
        name: tempItem.name,
        argument: tempItem.closedate_month,
        id: tempItem.dealid
      });
    }
  }
  return tempList;
}

export function getActiveDealsByMonthAge(items, valueField) {
  const tempList = [];
  let sourceList = pipelineStore.getPipelineItemsFromList(items);
  let tempValueField = valueField ? valueField : VALUE_MRR;

  var i;
  for (i = 0; i < sourceList.length; i++) {
    const tempItem = sourceList[i];
    if (tempItem.value_mrr_percentage > 0 && tempItem.isactive) {
      tempList.push({
        seriesname: tempItem.dealage_range,
        value: tempItem[tempValueField],
        // value: tempItem.value_mrr_percentage,
        name: tempItem.name,
        argument: tempItem.closedate_month,
        id: tempItem.dealid
      });
    }
  }
  return tempList;
}

export function getActiveDealsByMonthSalesCycle(items, valueField) {
  const tempList = [];
  let sourceList = pipelineStore.getPipelineItemsFromList(items);
  let tempValueField = valueField ? valueField : VALUE_MRR;

  var i;
  for (i = 0; i < sourceList.length; i++) {
    const tempItem = sourceList[i];
    if (tempItem.value_mrr_percentage > 0 && tempItem.isactive) {
      tempList.push({
        seriesname: tempItem.estimatedsalescylce_range,
        value: tempItem[tempValueField],
        // value: tempItem.value_mrr_percentage,
        name: tempItem.name,
        argument: tempItem.closedate_month,
        id: tempItem.dealid
      });
    }
  }
  return tempList;
}

export function getActiveDealsBySalesCycle(items, valueField) {
  const tempList = [];
  let sourceList = pipelineStore.getPipelineItemsFromList(items);
  let tempValueField = valueField ? valueField : VALUE_MRR;

  var i;
  for (i = 0; i < sourceList.length; i++) {
    const tempItem = sourceList[i];
    if (tempItem.value_mrr_percentage > 0 && tempItem.isactive) {
      tempList.push({
        seriesname: tempItem.dealowner,
        value: tempItem[tempValueField],
        // value: tempItem.value_mrr_percentage,
        name: tempItem.name,
        argument: tempItem.estimatedsalescylce_range,
        id: tempItem.dealid
      });
    }
  }
  return tempList;
}

export function getActiveDealsByDealAge(items, valueField) {
  const tempList = [];
  let sourceList = pipelineStore.getPipelineItemsFromList(items);
  let tempValueField = valueField ? valueField : VALUE_MRR;

  var i;
  for (i = 0; i < sourceList.length; i++) {
    const tempItem = sourceList[i];
    if (tempItem.value_mrr_percentage > 0 && tempItem.isactive) {
      tempList.push({
        seriesname: tempItem.dealowner,
        value: tempItem[tempValueField],
        // value: tempItem.value_mrr_percentage,
        name: tempItem.name,
        argument: tempItem.dealage_range,
        id: tempItem.dealid
      });
    }
  }
  return tempList;
}

export function getWonDealsByType(items, dealOwner, valueField) {
  const tempList = [];
  let sourceList = pipelineStore.getPipelineItemsFromList(items);
  let tempValueField = valueField ? valueField : VALUE_MRR;

  var i;
  for (i = 0; i < sourceList.length; i++) {
    const tempItem = sourceList[i];
    let tempIsOk = tempItem.dealowner === dealOwner;
    if (!dealOwner) {
      tempIsOk = true;
    }
    // check if it is Lost
    if (tempIsOk === true) {
      tempIsOk = tempItem.dealstage === DEALSTAGE_CLOSED_WON;
    }
    if (tempIsOk === true) {
      tempList.push({
        seriesname: tempItem.type,
        value: tempItem[tempValueField],
        name: tempItem.name,
        argument: tempItem.closedate_month,
        id: tempItem.dealid
      });
    }
  }
  return tempList;
}

export function getWonAndActiveDealsByDealOwner(items, dealOwner, valueField) {
  const tempList = [];
  let sourceList = pipelineStore.getPipelineItemsFromList(items);
  const stagesLost = [DEALSTAGE_CLOSED_LOST, DEALSTAGE_CLOSED_CHURNED];
  let tempValueField = valueField ? valueField : VALUE_MRR;

  var i;
  for (i = 0; i < sourceList.length; i++) {
    const tempItem = sourceList[i];
    let tempIsOk = tempItem.dealowner === dealOwner;
    if (!dealOwner) {
      tempIsOk = true;
    }
    // check if it is Lost
    if (tempIsOk === true) {
      tempIsOk = stagesLost.indexOf(tempItem.dealstage) === -1;
    }

    if (tempIsOk === true) {
      tempList.push({
        seriesname: tempItem.dealstage,
        value: tempItem[tempValueField],
        name: tempItem.name,
        argument: tempItem.closedate_month,
        id: tempItem.dealid
      });
    }
  }
  return tempList;
}
export function getActiveDealsBySizeOwner(items, valueField) {
  const tempList = [];
  let sourceList = pipelineStore.getPipelineItemsFromList(items);
  let tempValueField = valueField ? valueField : VALUE_MRR;

  var i;
  for (i = 0; i < sourceList.length; i++) {
    const tempItem = sourceList[i];
    if (tempItem.value_mrr_percentage > 200 && tempItem.isactive) {
      tempList.push({
        type: tempItem.type,
        value: tempItem[tempValueField],
        name: tempItem.name,
        argument: tempItem.dealowner,
        id: tempItem.dealid
      });
    }
  }
  return tempList;
}

export function getClosedDealsByMonthOwner(items, months, valueField) {
  const tempList = [];
  let sourceList = pipelineStore.getPipelineItemsFromList(items);
  let tempValueField = valueField ? valueField : VALUE_MRR;
  let monthCount =
    months === undefined || months === 0 ? store.historyMonthCount : months;

  var i;
  const todayDate = new Date();
  for (i = 0; i < sourceList.length; i++) {
    const tempItem = sourceList[i];

    const monthsBetween = differenceInMonths(tempItem.closedate, todayDate);
    const isOK =
      monthsBetween > -monthCount &&
      tempItem.dealstage === DEALSTAGE_CLOSED_WON &&
      !tempItem.isactive;

    if (isOK) {
      tempList.push({
        seriesname: tempItem.dealowner,
        value: tempItem[tempValueField],
        name: tempItem.name,
        argument: tempItem.closedate_month,
        id: tempItem.dealid
      });
    }
  }
  return tempList;
}

export function getWonLostDealsByMonth(items, dealOwner, months, valueField) {
  let sourceList = pipelineStore.getPipelineItemsFromList(items);
  let tempValueField = valueField; // ? valueField : VALUE_MRR;
  let monthCount =
    months === undefined || months === 0 ? store.historyMonthCount : months;
  // define last months data
  let monthsList = [];
  const todayDate = new Date();
  const stages = [
    DEALSTAGE_CLOSED_WON,
    DEALSTAGE_CLOSED_LOST,
    DEALSTAGE_CLOSED_CHURNED,
  ];

  var i;
  for (i = 0; i < sourceList.length; i++) {
    const tempItem = sourceList[i];
    let itemOk = stages.indexOf(tempItem.dealstage) !== -1;

    const dateTest = getMonthValue(tempItem.closedate);
    const diffTest = differenceInMonths(dateTest, todayDate);
    if (diffTest < -monthCount || diffTest > 0) {
      itemOk = false;
    }
    if (dealOwner) {
      if (tempItem.dealowner !== dealOwner) {
        itemOk = false;
      }
    }

    if (itemOk === true) {
      const tempMonth = format(
        getMonthValue(tempItem.closedate),
        store.monthFormatShort
      ).toString();
      let tempLost = 0;
      let tempWon = 0;
      let tempChurned = 0;
      let tempValue = tempValueField ? tempItem[tempValueField] : 1;

      if (tempItem.dealstage === DEALSTAGE_CLOSED_WON) {
        tempWon = tempValue;
      }
      if (tempItem.dealstage === DEALSTAGE_CLOSED_LOST) {
        tempLost = -tempValue;
      }
      if (tempItem.dealstage === DEALSTAGE_CLOSED_CHURNED) {
        tempChurned = -tempValue;
      }

      // push the record
      monthsList.push({
        closedate: new Date(tempItem.closedate),
        month: tempMonth,
        lost: tempLost,
        churned: tempChurned,
        name: tempItem.name,
        won: tempWon,
        id: tempItem.dealid
      });
    }
  }
  const returnList = monthsList.sort((a, b) => a.month.localeCompare(b.month));
  return returnList;
}
