import pipelineStore from "../store/StorePipeline";
import {
  DEALSTAGE_CLOSED_WON,
  // DEALSTAGE_CLOSED_LOST,
  // DEALSTAGE_CLOSED_CHURNED,
  // VALUE_MRR,
  // VALUE_MRR_PERCENTAGE,
  // VALUE_AMOUNT,
} from "../utils/constants.js";

export function searchDealOwnerObjects(searchkey) {
  var searchKey = searchkey.toLowerCase();
  const tempList = pipelineStore.getActualDealOwners;

  try {
    var filtered = [];
    for (
      var i = 0;
      i < tempList.length;
      i++
    ) {
      const tempItem = tempList[i];
      const tempName = tempItem.name.toLowerCase();
      if (tempName.includes(searchKey)) {
        filtered.push({
          id: i,
          ownerid: i,
          type: "dealowner",
          sortinfo: tempItem.name,
          name: tempItem.name,
        });
      }
    }
    return filtered;
  } catch (err) {
    return [];
  }
}

export function getDealOwnerShortInfo(dealOwnerId) {
  let item = pipelineStore.getDealOwnerInfoFromId(dealOwnerId);

  if (item) {
    return item;
    // return {
    //   id: item.id,
    //   name: item.name,
    //   score: item.score,
    // };
  }
}

export function getDealOwnerObjects() {
  try {
    const tempList = pipelineStore.getActualDealOwners;
    var filtered = [];
    for (
      var i = 0;
      i < tempList.length;
      i++
    ) {
      const tempItem = tempList[i];
      filtered.push({
        id: i,
        ownerid: i,
        type: "dealowner",
        name: tempItem.name,
        score: tempItem.score,
      });
    }
    return filtered;
  } catch (err) {
    console.log("get dealowner", err);
    return [];
  }
}

export function processDealOwnerStatistics(listDealOwners, list) {
  const tempList = [];

  listDealOwners.forEach((element, index) => {
    // get the deal owner detail


    tempList.push({
      id: index,
      name: element,
      value_mrr: 0,
      value_mrr_percentage: 0,
      value_amount: 0,
      deals_count: 0,
      score: 0
    });
  });

  const listLength = list.length;
  let i = 0;
  for (i = 0; i < listLength; i++) {
    const tempDeal = list[i];
    // const tempDealOwner = tempDeal.dealowner;

    if (tempDeal.isactive) {
    } else if (tempDeal.dealstage === DEALSTAGE_CLOSED_WON) {
    }
  }
  return tempList;
}
