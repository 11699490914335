// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:9c99a054-54bc-4537-8dcd-5d99c12d229c",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_gCsJaoGkU",
    "aws_user_pools_web_client_id": "3o5sc3nbdtsr55mglquilovj4c",
    "oauth": {},
    "aws_user_files_s3_bucket": "pipetrendfiles182626-default",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://7xlfo2mphrdurhehfs5wb32xm4.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-aiaszva2ljbblfj7zeooqcstha"
};


export default awsmobile;
