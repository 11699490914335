import { isSameDay, isDate } from "date-fns";
import {
  getIsActiveByDealStage,
  getIsWonByDealStage,
} from "./imports-helper";

export function MapperFromSalesForce(item) {
  this.dealid = item.dealid;
  this.customerid = item.customerid;
  this.customername = item.customername;
  this.customerindustry = item.customerindustry;
  this.customerdepartment = item.customerdepartment;
  this.customeremployees = item.customeremployees;
  this.customerrating = item.customerrating;
  this.customercity = item.customercity;
  this.customerwebsite = item.customerwebsite;
  this.name = item.name;
  this.dealownerid = item.dealownerid;
  this.dealowner = item.dealowner;
  this.dealstage = item.dealstage;
  this.dealsize = item.dealsize;
  this.status = item.status;
  this.probability = item.probability;
  this.country = item.country;
  this.region = item.region;
  this.source = item.source;
  this.notes = item.notes;
  this.category = item.category;
  this.partner = item.partner;
  this.type = item.type;
  this.rating = item.rating;
  this.longitude = item.longitude;
  this.latitude = item.latitude;

  // dates
  this.closedate = item.closedate;
  this.lostdate = item.lostdate;
  this.createdate = item.createdate;
  // oaa hack - all old create dates start at 06.03.20
  const hackDayOAA = new Date(2020, 2, 6);
  if (isSameDay(this.createdate, hackDayOAA)) {
    const hackUseCaseDayOAA = item.createdate2;
    // hack for oaa
    if (isDate(hackUseCaseDayOAA)) {
      this.createdate = hackUseCaseDayOAA;
    }
  }
  this.churndate = item.churndate;
  this.lastactivitydate = item.lastactivitydate;
  this.lastmodifieddate = item.lastmodifieddate;

  // values
  this.value_mrr = item.value_mrr;
  this.value_arr = item.value_arr;
  this.value_amount = item.value_amount;
  this.value_service = item.value_service;

  // calculated fields
  this.isactive = (item.isactive) ? item.isactive : getIsActiveByDealStage(this.dealstage);
  this.iswon = (item.iswon) ? item.iswon : getIsWonByDealStage(this.dealstage);
  this.isdeleted = item.isdeleted;
}
