/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPipeline = /* GraphQL */ `
  mutation CreatePipeline(
    $input: CreatePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    createPipeline(input: $input, condition: $condition) {
      id
      bucket_id
      name
      description
      url_filename
      url_data
      record_count
      activedeals_count
      smells_count
      dealowner_count
      value_total
      value_weighted
      value_average
      value_churned
      value_won
      value_mrr_total
      value_mrr_weighted
      value_mrr_average
      value_mrr_won
      value_mrr_churned
      score
    }
  }
`;
export const updatePipeline = /* GraphQL */ `
  mutation UpdatePipeline(
    $input: UpdatePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    updatePipeline(input: $input, condition: $condition) {
      id
      bucket_id
      name
      description
      url_filename
      url_data
      record_count
      activedeals_count
      smells_count
      dealowner_count
      value_total
      value_weighted
      value_average
      value_churned
      value_won
      value_mrr_total
      value_mrr_weighted
      value_mrr_average
      value_mrr_won
      value_mrr_churned
      score
    }
  }
`;
export const deletePipeline = /* GraphQL */ `
  mutation DeletePipeline(
    $input: DeletePipelineInput!
    $condition: ModelPipelineConditionInput
  ) {
    deletePipeline(input: $input, condition: $condition) {
      id
      bucket_id
      name
      description
      url_filename
      url_data
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      buckets
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userName
      email
      avatar
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userName
      email
      avatar
    }
  }
`;
