/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPipeline = /* GraphQL */ `
  query GetPipeline($id: ID!) {
    getPipeline(id: $id) {
      id
      bucket_id
      user_id
      name
      description
      url_filename
      url_data
      snapshot_date
      snapshot_source
      type
      activedeals_count
      smells_count
      dealowner_count
      value_total
      value_weighted
      value_average
      value_churned
      value_won
      value_mrr_total
      value_mrr_weighted
      value_mrr_average
      value_mrr_won
      value_mrr_churned
      values_month
      values_owner
      score
      createdAt
      updatedAt
    }
  }
`;
export const listPipelines = /* GraphQL */ `
  query ListPipelines(
    $filter: ModelPipelineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPipelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bucket_id
        user_id
        name
        description
        url_filename
        url_data
        snapshot_date
        snapshot_source
        activedeals_count
        smells_count
        dealowner_count
        value_total
        value_weighted
        value_average
        value_churned
        value_won
        value_mrr_total
        value_mrr_weighted
        value_mrr_average
        value_mrr_won
        value_mrr_churned
        score
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      account_id
      name
      description
      email
      language
      avatar
      license
      buckets
      invitations {
        items {
          bucket_id
          bucket_name
          sender_name
          status
          createdAt
        }
      }
      createdAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        avatar
      }
      nextToken
    }
  }
`;
