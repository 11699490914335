export const strings = {
  en: {
    dashboardHome: "Home",
    dashboardActualPipeline: "Current Pipeline Data",
    globalHeatMaps: "Heat Maps",
    heatMapCountryActiveCustomers: "Pipeline & Customers by Country",
    heatMapCountryPipelineCustomers: "Pipeline Accounts by Country",
    heatMapCountryPipelineRevenues: "Pipeline Possible Revenues by Country",
    dashboardDidYouKnow:
      "Did you know that you can compare your pipeline snapshots with each other.",
    dashboardLearnMore: "Learn more!",
    dashboardStayUpToDate: "Decisions based on data!",
    globalMyPipelines: "My Pipelines",
    globalRefresh: "Refresh",
    globalShowData: "Data",
    globalShowPivot: "Pivot",
    globalPivotAnalytics: "Pivot Analytics",
    globalGoBack: "Go back",
    globalGlobalSearch: "Global Search",
    globalEnterSearchKey: "Enter Search Key",
    globalSettings: "Settings",
    globalProfile: "Profile",
    globalNotifications: "Notifications",
    globalInvitations: "Invitations",
    globalNoEntriesAvailable: "No entries available",
    PipelineDataCaption: "Pipeline Data: "

  },
  de: {
    dashboardHome: "Pipeline Snapshots",
    dashboardActualPipeline: "Aktuelle Pipeline",
    globalHeatMaps: "Heat Maps",
    heatMapCountryActiveCustomers: "Kunden- und Pipelineübersicht nach Ländern",
    heatMapCountryPipelineCustomers: "Pipeline-Firmen nach Ländern",
    heatMapCountryPipelineRevenues: "Potentielle Pipeline-Umsätze nach Ländern",
    dashboardDidYouKnow:
      "Did you know that you can compare your pipeline snapshots with each other.",
    dashboardLearnMore: "Learn more!",
    dashboardStayUpToDate: "Decisions based on data!",
    globalMyPipelines: "Meine Pipelines",
    globalRefresh: "Aktualisieren",
    globalShowData: "Daten",
    globalShowPivot: "Pivot",
    globalPivotAnalytics: "Pivot Analyse",
    globalGoBack: "Zurück",
    globalGlobalSearch: "Globale Suche",
    globalEnterSearchKey: "Suchbegriff",

    globalSettings: "Einstellungen",
    globalProfile: "Profil",
    globalNotifications: "Mitteilungen",
    globalInvitations: "Einladungen",
    globalNoEntriesAvailable: "Keine Einträge vorhanden",
    PipelineDataCaption: "Pipeline Daten: "
  },
};
