import { differenceInMonths } from "date-fns";
import { formatDateTimeMonth } from "../utils/helper";
import { NOT_AVAILAVLE } from "../utils/constants";
import {
  getMRRRange,
  getSalesCycleRange,
  getAgeRange,
  getIsActiveByDealStage,
  getIsWonByDealStage,
} from "./imports-helper";

export function MapperFromStorage(item) {
  this.dealid = item.dealid;
  this.name = item.name;
  this.dealownerid = item.dealownerid;
  this.dealowner = item.dealowner;
  this.customerid = item.customerid;
  this.customername = item.customername;
  this.customerdepartment = item.customerdepartment;
  this.probability = item.probability;
  this.dealstage = item.dealstage;
  this.dealsize = item.dealsize;
  this.isdeleted = item.isdeleted;
  this.status = item.status;
  this.country = item.country;
  this.region = item.region;
  this.customerindustry = item.industry ? item.industry : item.customerindustry;
  this.customeremployees = item.customeremployees;
  this.customerrating = item.customerrating;
  this.customercity = item.customercity;
  this.customerwebsite = item.customerwebsite;
  this.source = item.source;
  this.notes = item.notes;
  this.category = item.category;
  this.partner = item.partner;
  this.longitude = item.longitude;
  this.latitude = item.latitude;

  this.type = item.type;
  this.rating = item.rating;
  this.score = 2.2;
  this.rowmessage = item.rowmessage;

  // values
  this.value_mrr = item.value_mrr;
  this.value_arr = item.value_arr;
  this.value_amount = item.value_amount;
  this.value_service = item.value_service;

  // dates
  this.createdate = item.createdate ? new Date(item.createdate) : undefined;
  this.closedate = item.closedate ? new Date(item.closedate) : undefined;
  this.lostdate = item.lostdate ? new Date(item.lostdate) : undefined;
  this.churndate = item.churndate ? new Date(item.churndate) : undefined;
  this.lastmodifieddate = item.lastmodifieddate ? new Date(item.lastmodifieddate) : undefined;
  this.lastactivitydate = item.lastactivitydate
    ? new Date(item.lastactivitydate)
    : undefined;

  // calculated fields
  this.isactive = item.isactive
    ? item.isactive
    : getIsActiveByDealStage(this.dealstage);
  this.iswon = item.iswon ? item.iswon : getIsWonByDealStage(this.dealstage);
  this.isdeleted = item.isdeleted;
  this.createdate_month = item.createdate_month
    ? new Date(item.createdate_month)
    : undefined;
  this.closedate_month = item.closedate_month
    ? formatDateTimeMonth(new Date(item.closedate_month))
    : undefined;
  this.value_arr_percentage = item.value_arr_percentage;
  this.value_amount_percentage = item.value_amount_percentage;
  this.value_service_percentage = item.value_service_percentage;
  this.value_mrr_percentage = item.value_mrr_percentage;
  this.value_mrr_range = getMRRRange(this.value_mrr);
  this.closedate_inmonths = item.closedate_inmonths;
  this.lastactivitydate_inmonths = item.lastactivitydate_inmonths;
  this.estimatedsalescylce_inmonths = !isNaN(this.createdate)
    ? differenceInMonths(this.closedate, this.createdate)
    : 0;
  if (this.estimatedsalescylce_inmonths < 0) {
    this.estimatedsalescylce_inmonths = 0;
  }
  this.dealage_inmonths = !isNaN(this.createdate)
    ? differenceInMonths(new Date(), this.createdate)
    : 0;
  if (this.dealage_inmonths < 0) {
    this.dealage_inmonths = 0;
  }
  this.estimatedsalescylce_range = getSalesCycleRange(
    this.estimatedsalescylce_inmonths
  );
  this.dealage_range = getAgeRange(this.dealage_inmonths);
  this.deal_url = item.dealid
    ? "https://oaa.lightning.force.com/lightning/r/Opportunity/" +
      item.dealid +
      "/view"
    : undefined;
}
