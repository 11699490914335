import React, { useState, useEffect } from "react";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import { observer } from "mobx-react";
import { Layout, Button } from "antd";
import { Auth } from "aws-amplify";
// ---
import LoadingAnimation from "../../components/LoadingAnimation";
import { HeaderControl } from "../../components/HeaderControl";
import { ElementInvitationsGrid } from "../../elements/ElementInvitationGrid";
import store from "../../store/StoreMain";

export function WelcomeView(props) {
  const { Content, Sider } = Layout;
  const [dataloading, setDataLoading] = useState(false);

  const [userName, setUserName] = useState("");

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => {
        setUserName(user.username);
        //Auth.LogOut();
        setDataLoading(false);
      })
      .catch((err) => {
        console.log("profile user: ", err);
      });
  }, []);

  let contents = dataloading ? (
    <LoadingAnimation />
  ) : (
    <div className="animated fadeIn">
      <Layout style={{ backgroundColor: "transparent" }}>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          defaultCollapsed={store.isPhone}
          width={300}
          trigger={null}
          className="site-layout-background"
          style={{
            backgroundColor: store.colorPrimary,
            marginLeft: 0,
            height: "100vh"
          }}
        ></Sider>
        <Content
          className="site-layout-background"
          style={{
            padding: 40,
          }}
        >
          <HeaderControl
            title={"Welcome " + userName}
            description={""}
            history={props.history}
          ></HeaderControl>{" "}
          <div class="container">
          <br />
          You have no data. What would you like to do?
          <br />
          <br />
          <br />
          <Button class="btn-block">Connect pipetrend to your Salesforce account</Button>
          <br />
          <br />
          <Button>Connect pipetrend to your HubSpot account</Button>
          <br />
          <br />
          <Button>Get your data based on Microsoft Excel files</Button>
          <br />
          <br />
          <br />
          <br />
          Or perhaps accept an open invitation
          <br />
          <br />
          <ElementInvitationsGrid caption="Invitations" items={store.getUserInvitations} />
          <br />
          <br />

          <AmplifySignOut />
          </div>
          <br />
        </Content>
      </Layout>
    </div>
  );

  return <div>{contents}</div>;
}

export default observer(WelcomeView);
