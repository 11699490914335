import React, { useState } from "react";
import { Typography, Space, Select, Button } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import store from "../../store/StoreMain";

export function ElementHeader(props) {
  const [rangeMonths, setRangeMonths] = useState("all");
  const [activeValue, setActiveValue] = useState("value1");
  const [activeView, setActiveView] = useState("chart");
  const [viewVisible, setViewVisible] = useState(true);
  const { Text } = Typography;
  const [showMonthRange, setShowMonthRange] = useState(
    props.showmonthrange === true
  );
  const [showValueType, setShowValueType] = useState(
    props.showvaluetype === true
  );
  const { Option, OptGroup } = Select;

  function _handleRangeChange(e) {
    setRangeMonths(e);
  }

  function _handleValueChange(e) {
    setActiveValue(e);
  }

  function _handleViewChange(e) {
    setActiveView(e);
    if (props.setviewtype) {
      props.setviewtype(e);
    }
  }

  function _handleVisibleChange(e) {
    const tempVisible = !viewVisible;
    setViewVisible(tempVisible);
    if (props.setvisiblestate) {
      props.setvisiblestate(tempVisible);
    }
  }

  return (
    <div
      style={{
        paddingLeft: 2,
        paddingRight: 0,
        paddingTop: 2,
        paddingBottom: 0,
      }}
    >
      <div className="row justify-content-between align-items-center">
        {/* <div className="col-auto" style={{ paddingTop: 10, paddingBottom: 10 }}>
          <strong>{props.caption}</strong>
        </div> */}
        <Text
          style={{
            fontSize: store.isPhone ? "14px" : "16px",
            paddingLeft: 15,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <strong>{props.caption}</strong>
        </Text>
        <div className="col-auto" style={{ paddingRight: 8 }}>
          {/* <Space size={10}> */}
          <small>
            <Space size={10}>
              {props.children && props.children}
              {showMonthRange && viewVisible && (
                <Select
                  defaultValue={rangeMonths}
                  style={{
                    width: 120,
                    backgroundColor: store.optionsDarkMode
                      ? "lightgray"
                      : "transparent",
                  }}
                  onChange={_handleRangeChange}
                >
                  <OptGroup label="Months">
                    <Option value="3m">3 months</Option>
                    <Option value="6m">6 months</Option>
                    <Option value="9m">9 months</Option>
                  </OptGroup>
                  <OptGroup label="Years">
                    <Option value="1y">1 year</Option>
                    <Option value="all">All data</Option>
                  </OptGroup>
                </Select>
              )}
              {showValueType && viewVisible && (
                <Select
                  defaultValue={activeValue}
                  style={{
                    width: 100,
                  }}
                  onChange={_handleValueChange}
                >
                  <Option value="value1">MRR %</Option>
                  <Option value="value2">MRR</Option>
                  <Option value="value3">Amount</Option>
                  <Option value="valueCount">Count</Option>
                </Select>
              )}
              {props.setviewtype && viewVisible && (
                <Select defaultValue={activeView} onChange={_handleViewChange}>
                  <Option value="chart">Chart</Option>
                  <Option value="data">Data</Option>
                </Select>
              )}
            </Space>
          </small>
          {/* <Button
              type="link"
              size="small"
              style={{ verticalAlign: "middle", marginBottom: 6 }}
            >
              <SettingOutlined />
            </Button> */}
          {props.setvisiblestate && (
            <Button
              type="link"
              size="small"
              style={{
                marginLeft: 8,
                verticalAlign: "middle",
                marginBottom: 6,
              }}
              onClick={() => _handleVisibleChange()}
            >
              {viewVisible && <CaretUpOutlined />}
              {!viewVisible && <CaretDownOutlined />}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ElementHeader;
