import React, { useState, useEffect } from "react";
import { CLabel } from "@coreui/react";
import { NOT_AVAILAVLE } from "../utils/constants";

export function HeaderControl(props) {
  const [parentName, setParentName] = useState("");
  const title = props.title != null ? props.title : NOT_AVAILAVLE;

  useEffect(() => {
    setParentName(props.parentName);
  }, []);

  return (
    <>
      <h5 style={{ paddingLeft: 0 }}>
        <CLabel>{title}</CLabel>
      </h5>
    </>
  );
}

export default HeaderControl;
