import { isDate } from "date-fns";
import { getMonthValue } from "../utils/helper";
import {
  getMRRRange,
  getSalesCycleRange,
  getAgeRange,
  getIsActiveByDealStage,
  getIsWonByDealStage,
  getValuePercentage,
  getDifferenceInMonths,
} from "./imports-helper";

export function enrichDealRecord(item) {
  let newItem = item;

  // calculated fields
  newItem.isactive = item.isactive
    ? item.isactive
    : getIsActiveByDealStage(item.dealstage);
  newItem.iswon = item.iswon ? item.iswon : getIsWonByDealStage(item.dealstage);

  newItem.createdate_month = getMonthValue(item.createdate);

  newItem.dealage_inmonths = getDifferenceInMonths(new Date(), item.createdate);
  newItem.dealage_range = getAgeRange(item.dealage_inmonths);

  newItem.closedate_month = getMonthValue(item.closedate);
  newItem.closedate_inmonths = newItem.isactive
    ? getDifferenceInMonths(item.closedate, new Date())
    : 0;

  newItem.lostdate_month = isDate(item.lostdate)
    ? getMonthValue(item.lostdate)
    : undefined;

  newItem.lastactivitydate_inmonths =
    item.lastactivitydate > 0
      ? getDifferenceInMonths(new Date(), item.lastactivitydate)
      : 0;

  newItem.estimatedsalescylce_inmonths = getDifferenceInMonths(
    item.closedate,
    item.createdate
  );
  if (newItem.estimatedsalescylce_inmonths < 0) {
    newItem.estimatedsalescylce_inmonths = 0;
    newItem.estimatedsalescylce_range = undefined;
  } else {
    newItem.estimatedsalescylce_range = getSalesCycleRange(
      item.estimatedsalescylce_inmonths
    );
  }

  // values
  newItem.value_mrr_percentage = getValuePercentage(
    item.value_mrr,
    item.probability
  );
  newItem.value_arr_percentage = getValuePercentage(
    item.value_arr_percentage,
    item.probability
  );
  newItem.value_amount_percentage = getValuePercentage(
    item.value_amount,
    item.probability
  );
  newItem.value_service_percentage = getValuePercentage(
    item.value_service,
    item.probability
  );

  newItem.value_mrr_range = getMRRRange(item.value_mrr);

  // link to the crm item
  newItem.deal_url = item.dealid
    ? "https://oaa.lightning.force.com/lightning/r/Opportunity/" +
      item.dealid +
      "/view"
    : undefined;

  newItem.customer_url = item.customerid
    ? "https://oaa.lightning.force.com/lightning/r/Account/" +
      item.customerid +
      "/view"
    : undefined;

  // attention hint for this entry
  newItem.rowmessage =
    item.lastactivitydate > 0 &&
    item.probability > 0 &&
    item.lastactivitydate_inmonths > 2
      ? "attention"
      : "";

  newItem.score = item.score;

  return newItem;
}
